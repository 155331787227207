<template lang="pug">
validation-observer(ref='formInstitutionPreferences')
    b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
        h3.mb-2 Types de documents
        b-form-group
          b-form-checkbox.mb-1.mr-0(style="width: 100px" :disabled="item.id==4 && !checkFunctionUser(37, 'all')" v-for="(item, index) in institutionTypesList"
            v-model="institutionSettings.institutionTypes" :value="item" class="custom-control-primary" inline :key="index")
            | {{item.label}}
        h3.mb-2 Comptes de TVA
        .d-flex.align-items-start.align-items-md-center.bg-mobile.mb-1.mb-md-0.p-1.p-md-0.mb-0.mb-md-1(v-for="(element, index) of institutionSettings.referencielTvas")
          div
            b-row.my-0 
              b-col.py-0(cols='12')
                b-form-checkbox.mt-50(v-model="element.isDefault" inline class="cursor-pointer"  @input="changeDefautTva(index, element.isDefault)" :disabled="element.isDefault")
          .d-flex.flex-column.flex-md-row.my-0.w-100.align-items-end
              .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                label.w-100(label-for='referencielTvaId') Taux de TVA *
                v-select.w-100(:loading="isLoadingReferencielTVAsList"
                  :clearable="false"
                  :closeOnSelect="true"
                  v-model="element.referencielTva" 
                  :disabled="element.referencielTva && element.referencielTva.id>0"
                  :options='element.referencielTva ? [element.referencielTva] : referencielTVAsListFiltered'
                  @input="affectedReferencielTva")
                  template(v-slot:no-options='')
                      template  Aucun r&eacute;sultat trouv&eacute;
              .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                label.w-100(label-for='creditAccount') Compte de crédit
                b-form-group.w-100
                  b-form-input#creditAccount.w-100.not-autocomplete(type="text" v-model='element.creditAccount' autocomplete='nope' aria-autocomplete='nope')
              .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column
                label.w-100(label-for='collectedTvaAccount') Compte de TVA collecté
                b-form-group.w-100
                  b-form-input#collectedTvaAccount.w-100.not-autocomplete(type="text" :disabled="element.referencielTva && element.referencielTva.id == 5" v-model='element.collectedTvaAccount' autocomplete='nope' aria-autocomplete='nope')
              b-button.mt-1.py-0.ml-0.ml-md-1(style="height: 32px" variant="outline-danger" @click="deleteReferencielTva(index, element)") 
                feather-icon.m-0(icon="TrashIcon")
        div.mb-1(v-if="referencielTVAsListFiltered.length" @click="addTvaForm" v-show="institutionSettings.referencielTvas && institutionSettings.referencielTvas.length>0 && institutionSettings.referencielTvas.find(elem=>!elem.referencielTva) ? false : true")
          small.cursor-pointer.text-primary + Ajouter un taux de TVA
        vs-divider.mt-1.mb-1(color='#cccccc')
        h3 Coefficient de marge par défaut
        b-row.my-0()
          b-col.py-0.mb-1(cols='4')
            b-form-group(label='Fourniture' label-for='supplyMarginDefault')
              ejs-numerictextbox(id="supplyMarginDefault" v-model='institutionSettings.supplyMarginDefault'  format="##.##" :decimals="2" :step="0.01")
          b-col.py-0.mb-1(cols='4')
            b-form-group(label='Main d\'oeuvre' label-for='manPowerMarginDefault')
              ejs-numerictextbox(id="manPowerMarginDefault" v-model='institutionSettings.manPowerMarginDefault' format="##.##" :decimals="2" :step="0.01")
          b-col.py-0.mb-1(cols='4')
            b-form-group(label='Ouvrage' label-for='laborMarginDefault')
              ejs-numerictextbox(id="laborMarginDefault" v-model='institutionSettings.laborMarginDefault' format="##.##" :decimals="2" :step="0.01")
          b-col.py-0.mb-1(cols='4')
            b-form-group(label='Sous-traitance' label-for='subContractingMarginDefault')
              ejs-numerictextbox(id="subContractingMarginDefault" v-model='institutionSettings.subContractingMarginDefault' format="##.##" :decimals="2" :step="0.01")
          b-col.py-0.mb-1(cols='4')
            b-form-group(label='Matérial' label-for='equipementMarginDefault')
              ejs-numerictextbox(id="equipementMarginDefault" v-model='institutionSettings.equipementMarginDefault' format="##.##" :decimals="2" :step="0.01")
          b-col.py-0.mb-1(cols='4')
            b-form-group(label='Autres' label-for='variousMarginDefault')
              ejs-numerictextbox(id="variousMarginDefault" v-model='institutionSettings.variousMarginDefault' format="##.##" :decimals="2" :step="0.01")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BButton, BFormRadioGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { checkFunctionUser } from "@/auth/utils.ts";
export default {
  props: {
    errorsCustom: {
      default: false,
    },
  },
  data() {
    return {
      tvafields: [
        { key: "referencielTva.Id", label: "Taux de TVA" },
        { key: "creditAccount", label: "Compte de Crédit de TVA" },
        { key: "collectedTvaAccount", label: "Compte de Tva Collecté" },
        "Actions",
      ],
      tvaForm: {
        referencielTva: null,
        creditAccount: "",
        collectedTvaAccount: "",
        isDefault: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "workspaceSelected",
      "isLoadingReferencielTVAsList",
      "referencielTVAsList",
      "institutionTypesList",
    ]),
    institutionSettings: {
      get() {
        return this.$store.getters.institutionSettings;
      },
      set(value) {
        this.$store.commit("SET_INSTITUTION_SETTINGS", value);
      },
    },
    referencielTVAsListFiltered() {
      if (
        this.institutionSettings.referencielTvas &&
        this.institutionSettings.referencielTvas.length > 0
      ) {
        let referencielTVAsListFiltered = [];

        for (let index = 0; index < this.referencielTVAsList.length; index++) {
          const element = this.referencielTVAsList[index];
          if (
            !this.institutionSettings.referencielTvas.find(
              (el) => el.referencielTva && el.referencielTva.id == element.id
            )
          ) {
            referencielTVAsListFiltered.push(element);
          }
        }
        return referencielTVAsListFiltered;
      } else {
        return this.referencielTVAsList;
      }
    },
  },
  methods: {
    checkFunctionUser,
    affectedReferencielTva(args) {
      let referencielTva = this.referencielTVAsList.find((el) => el.id == args);
      let index = this.institutionSettings.referencielTvas.findIndex(
        (el) => el.referencielTva && el.referencielTva.id == args
      );
      if (index > -1) {
        this.institutionSettings.referencielTvas[index].referencielTva =
          referencielTva;
      }
    },
    changeDefautTva(number, isDefault) {
      if (isDefault) {
        for (
          let index = 0;
          index < this.institutionSettings.referencielTvas.length;
          index++
        ) {
          if (index == number) {
            this.institutionSettings.referencielTvas[index].isDefault = true;
          } else {
            this.institutionSettings.referencielTvas[index].isDefault = false;
          }
        }
      }
    },
    addTvaForm() {
      let isDefault = true;
      if (this.institutionSettings.referencielTvas.length > 0) {
        if (
          this.institutionSettings.referencielTvas.find(
            (elem) => elem.isDefault == true
          )
        ) {
          isDefault = false;
        }
      }
      let referencielsTvas = [];
      if (
        this.institutionSettings.referencielTvas &&
        this.institutionSettings.referencielTvas.length > 0
      ) {
        let referencielTVAsListFiltered = [];

        for (let index = 0; index < this.referencielTVAsList.length; index++) {
          const element = this.referencielTVAsList[index];
          if (
            !this.institutionSettings.referencielTvas.find((el) => {
              return el.referencielTva && el.referencielTva.id == element.id;
            })
          ) {
            referencielTVAsListFiltered.push(element);
          }
        }
        referencielsTvas = referencielTVAsListFiltered;
      } else {
        referencielsTvas = this.referencielTVAsList;
      }
      this.institutionSettings.referencielTvas.push({
        referencielTva: null,
        creditAccount: null,
        collectedTvaAccount: null,
        isDefault: isDefault,
      });
    },
    async deleteReferencielTva(index, element) {
      this.institutionSettings.referencielTvas.splice(index, 1);
      if (
        element.isDefault == true &&
        this.institutionSettings.referencielTvas.length > 0
      ) {
        this.institutionSettings.referencielTvas[0].isDefault = true;
      }
    },
  },
  components: {
    BButton,
    BFormRadioGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style lang="scss">
.bg-mobile {
  background-color: transparent;
}

@media screen and (max-width: 859px) {
  .bg-mobile {
    background-color: #f8f9fa;
  }
}

.checkbox-column {
  display: flex;
  flex-direction: column;
  width: 25%;
}

@media screen and (max-width: 1350px) {
  .checkbox-column {
    width: 33.33%;
  }
}

@media screen and (max-width: 1050px) {
  .checkbox-column {
    width: 50%;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 790px) {
  .checkbox-column {
    width: 100%;
    padding-bottom: 20px;
  }
}

.sub-checkbox {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #ccc;
}
</style>
