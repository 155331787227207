<template lang="pug">
  validation-observer(ref='formInstitutionPreferences')
    b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
      h3.mb-2 Paramétrage par défaut de vos bons de commande client
      b-row.my-0
        b-col.py-0.mb-1(cols='12')
          b-form-group(label='Durée de validité *' label-for='validityOrderFormCustomerDefault')
            validation-provider(#default='{ errors }' name='validityOrderFormCustomerDefault' rules="required")
              v-select#validityOrderFormCustomerDefault(:class="!orderFormCustomerOptionDefault.documentValidity && errorsCustom ? 'style-chooser' : ''" :loading="isLoadingDocumentValidityList" :deselectFromDropdown="true" :closeOnSelect="true" :state="errors.length > 0 ? false : null" v-model="orderFormCustomerOptionDefault.documentValidity" :options="documentValidityList" :reduce="elem => elem.value")
                template(v-slot:no-options='')
                  template  Aucun r&eacute;sultat trouv&eacute;
              small(class="text-danger") {{ errors[0] }}
      b-row.my-0()
        b-col.py-0.mb-1(cols='12')
          b-form-group(label='Notes de bas de page' label-for='commentOrderFormCustomerDefault')
            validation-provider(#default='{ errors }' name='commentOrderFormCustomerDefault')
              quill-editor(v-model="orderFormCustomerOptionDefault.comment" :options="editorOption" class="edit")
      b-row.my-0()
        b-col.py-0.mb-1(cols='12')
          b-form-group(label='Mentions légales' label-for='additionnalLegalNoticeOrderFormCustomerDefault')
            validation-provider(#default='{ errors }' name='additionnalLegalNoticeOrderFormCustomerDefault')
              quill-editor(v-model="orderFormCustomerOptionDefault.additionalLegalNotices" :options="editorOption" class="edit")
      vs-divider.mt-1.mb-1(color='#cccccc')
      h3 Options par défaut des bons de réception
      p.mb-2 Ces options seront appliquées par défaut à tous vos prochains bons de réception.
        .d-flex.flex-wrap
          .pr-2.checkbox-column
            h4.primary.pb-50 Edition
            .d-flex.flex-column.justify-content-start
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.calculateMargin")
                  | Calculer la marge
              //- .d-flex.w-100.pb-1
              //-   b-form-checkbox(v-model="orderFormCustomerOptionDefault.calculateUnitPriceHtByUnitPurchasePriceHt")
              //-     | Calculer le prix de vente par rapport au prix d'achat
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showDiscountColumn")
                  | Afficher la colonne des remises par ligne
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showReferenceColumn")
                  | Afficher la colonne des références
          .pr-2.checkbox-column
            h4.primary.pb-50 Prévisualisation
            .d-flex.flex-column.justify-content-start
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideInstitutionLogo")
                  | Masquer mon logo
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideInstitutionName")
                  | Masquer ma raison sociale
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideInstitutionAddress")
                  | Masquer mon adresse
              .d-flex.w-100.mb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideCollaboratorInCharge")
                  | Masquer le collaborateur en charge
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideAffair")
                  | Masquer l'affaire
              .d-flex.w-100.mb-1.sub-checkbox
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showAffairCode" :disabled="orderFormCustomerOptionDefault.hideAffair")
                  | Masquer le code de l'affaire
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideLinesQuantityEmpty")
                  | Masquer les lignes sans quantité
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideTotal")
                  | Masquer le total
              .d-flex.w-100.mb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showWaterMark")
                  | Afficher le filigrane
              //- .d-flex.w-100.pb-1
              //-   b-form-checkbox(v-model="orderFormCustomerOptionDefault.showDetailWorkLine")
              //-     | Afficher le détail des ouvrages
              //- .d-flex.w-100.pb-1
              //-   b-form-checkbox(v-model="orderFormCustomerOptionDefault.showThumbnail")
              //-     | Afficher la miniature des produits
              //- .d-flex.w-100.pb-1
              //-   b-form-checkbox(v-model="orderFormCustomerOptionDefault.showProductPicturesAttached")
              //-     | Afficher les images des produits en pièce jointe
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showTermsOfSales")
                  | Afficher les conditions de vente
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideInitialQuote")
                  | Masquer le devis initial
          .pr-2.checkbox-column
            h4.primary.pb-50 Document
            .d-flex.flex-column.justify-content-start
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideUser")
                  | Masquer le contact
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideIndexColumn")
                  | Masquer la colonne des index
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideQuantityColumn")
                  | Masquer la colonne des quantités
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideUnitColumn")
                  | Masquer la colonne des unités
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideUnitPriceHtColumn")
                  | Masquer la colonne des prix unitaire HT
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideReferencielTvaColumn")
                  | Masquer la colonne des TVA
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hidePriceHtColumn")
                  | Masquer la colonne des totaux HT
              .d-flex.w-100.pb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideBankAccount")
                  | Masquer le compte bancaire associé
          .checkbox-column
            h4.primary.pb-50 Client
            .d-flex.flex-column.justify-content-start
              .d-flex.w-100.mb-1
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.hideCompany")
                  | Masquer le client
              .d-flex.w-100.pb-1.sub-checkbox
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showCompanyCode" :disabled="orderFormCustomerOptionDefault.hideCompany")
                  | Afficher le code du client
              .d-flex.w-100.pb-1.sub-checkbox
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showCompanyAddressLabel" :disabled="orderFormCustomerOptionDefault.hideCompany")
                  | Afficher le libellé de l'adresse
              .d-flex.w-100.pb-1.sub-checkbox
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showCompanyMail" :disabled="orderFormCustomerOptionDefault.hideCompany")
                  | Afficher l'adresse mail
              .d-flex.w-100.pb-1.sub-checkbox
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showCompanyPhoneNumber" :disabled="orderFormCustomerOptionDefault.hideCompany")
                  | Afficher le numéro de téléphone
              .d-flex.w-100.pb-1.sub-checkbox
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showCompanySiret" :disabled="orderFormCustomerOptionDefault.hideCompany")
                  | Afficher le numéro de SIRET
              .d-flex.w-100.sub-checkbox
                b-form-checkbox(v-model="orderFormCustomerOptionDefault.showCompanyTva" :disabled="orderFormCustomerOptionDefault.hideCompany")
                  | Afficher le numéro de TVA intracommunautaire
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      editFocusComment: false,
      editFocusAdditionnalLegalNotice: false,
      editFocus: false,
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  props: {
    errorsCustom: {
      default: false,
    },
  },
  created() {
    if (!this.documentValidityList || this.documentValidityList.length == 0) {
      this.getDocumentValidityEnum({});
    }
    this.getOrderFormCustomerOptionDefaultByOrderFormCustomerId({});
  },
  computed: {
    ...mapGetters(["documentValidityList", "isLoadingDocumentValidityList"]),
    orderFormCustomerOptionDefault: {
      get() {
        return this.$store.getters.orderFormCustomerOptionDefaultForm;
      },
      set(value) {
        this.$store.commit(
          "SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT_FORM",
          value
        );
      },
    },
  },
  methods: {
    ...mapActions([
      "getPaymentConditionByWorkspaceId",
      "getDocumentValidityEnum",
      "getOrderFormCustomerOptionDefaultByOrderFormCustomerId",
    ]),
    // focusFunction(val, type) {
    //   if (type == "comment") {
    //     this.editFocusComment = val;
    //   } else if (type == "legalNotice") {
    //     this.editFocusAdditionnalLegalNotice = val;
    //   }
    // },
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
};
</script>
