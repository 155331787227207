<template lang="pug">
validation-observer(ref='formInstitutionPreferences')
  b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
    h3.mb-2 Paramétrage par défaut de vos devis
    //- b-row.my-0
    //-     b-col.py-0.mb-1(cols='12')
    //-       b-form-group(label='Numéro de départ de la séquence' label-for='documentNumberQuote' style='flex:1')
    //-         validation-provider(#default='{ errors }' name='documentNumberQuote' rules="required")
    //-           ejs-numerictextbox(v-model="quoteOptionDefault.documentNumber")
    b-row.my-0
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Durée de validité *' label-for='validityQuoteDefault')
          validation-provider(#default='{ errors }' name='validityQuoteDefault' rules="required")
            v-select#validityQuoteDefault(:class="!quoteOptionDefault.documentValidity && errorsCustom ? 'style-chooser' : ''" :loading="isLoadingDocumentValidityList" :deselectFromDropdown="true" :closeOnSelect="true" :state="errors.length > 0 ? false : null" v-model="quoteOptionDefault.documentValidity" :options="documentValidityList" :reduce="elem => elem.value")
              template(v-slot:no-options='')
                template  Aucun r&eacute;sultat trouv&eacute;
            small(class="text-danger") {{ errors[0] }}
    b-row.my-0()
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Notes de bas de page' label-for='commentQuoteDefault')
          validation-provider(#default='{ errors }' name='commentQuoteDefault')
            quill-editor(v-model="quoteOptionDefault.comment" :options="editorOption"  class="edit" )
    b-row.my-0()
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Mentions légales' label-for='additionnalLegalNoticeQuote')
          validation-provider(#default='{ errors }' name='additionnalLegalNoticeQuote')
            quill-editor(v-model="quoteOptionDefault.additionalLegalNotices" :options="editorOption" class="edit" )
    vs-divider.mt-1.mb-1(color='#cccccc')
    h3 Options par défaut des devis
    p.mb-2 Ces options seront appliquées par défaut à tous vos prochains devis.
    .d-flex.flex-wrap
      .pr-2.checkbox-column
        h4.primary.pb-50 Edition
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.calculateMargin")
              | Calculer la marge
          //- .d-flex.w-100.pb-1
            //- b-form-checkbox(v-model="quoteOptionDefault.calculateUnitPriceHtByUnitPurchasePriceHt")
            //-   | Calculer le prix de vente par rapport au prix d'achat
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.showDiscountColumn")
              | Afficher la colonne des remises par ligne
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.showReferenceColumn")
              | Afficher la colonne des références
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideWasteCollection")
              | Masquer la gestion des déchets
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.isReverseCharge")
              | Activer l'autoliquidation
      .pr-2.checkbox-column
        h4.primary.pb-50 Prévisualisation
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideInstitutionLogo")
              | Masquer mon logo
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideInstitutionName")
              | Masquer ma raison sociale
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideInstitutionAddress")
              | Masquer mon adresse
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideCollaboratorInCharge")
              | Masquer le collaborateur en charge
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideAffair")
              | Masquer l'affaire
          .d-flex.w-100.mb-1.sub-checkbox
            b-form-checkbox(v-model="quoteOptionDefault.showAffairCode" :disabled="quoteOptionDefault.hideAffair")
              | Masquer le code de l'affaire
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideLinesQuantityEmpty")
              | Masquer les lignes sans quantité
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.showDetailWorkLine")
              | Afficher le détail des ouvrages
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideTotal")
              | Masquer le total
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="quoteOptionDefault.showWaterMark")
              | Afficher le filigrane
          //- .d-flex.w-100.pb-1
          //-   b-form-checkbox(v-model="quoteOptionDefault.showThumbnail")
          //-     | Afficher la miniature des produits
          //- .d-flex.w-100.pb-1
          //-   b-form-checkbox(v-model="quoteOptionDefault.showProductPicturesAttached")
          //-     | Afficher les images des produits en pièce jointe
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.showTermsOfSales")
              | Afficher les conditions de vente
      .pr-2.checkbox-column
        h4.primary.pb-50 Document
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideUser")
              | Masquer le contact
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideIndexColumn")
              | Masquer la colonne des index
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideQuantityColumn")
              | Masquer la colonne des quantités
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideUnitColumn")
              | Masquer la colonne des unités
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideUnitPriceHtColumn")
              | Masquer la colonne des prix unitaire HT
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideReferencielTvaColumn")
              | Masquer la colonne des TVA
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hidePriceHtColumn")
              | Masquer la colonne des totaux HT
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideBankAccount")
              | Masquer le compte bancaire associé
      .checkbox-column
        h4.primary.pb-50 Client
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="quoteOptionDefault.hideCompany")
              | Masquer le client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="quoteOptionDefault.showCompanyCode" :disabled="quoteOptionDefault.hideCompany")
              | Afficher le code client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="quoteOptionDefault.showCompanyAddressLabel" :disabled="quoteOptionDefault.hideCompany")
              | Afficher le libellé de l'adresse
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="quoteOptionDefault.showCompanyMail" :disabled="quoteOptionDefault.hideCompany")
              | Afficher l'adresse mail
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="quoteOptionDefault.showCompanyPhoneNumber" :disabled="quoteOptionDefault.hideCompany")
              | Afficher le numéro de téléphone
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="quoteOptionDefault.showCompanySiret" :disabled="quoteOptionDefault.hideCompany")
              | Afficher le numéro de SIRET
          .d-flex.w-100.sub-checkbox
            b-form-checkbox(v-model="quoteOptionDefault.showCompanyTva" :disabled="quoteOptionDefault.hideCompany")
              | Afficher le numéro de TVA intracommunautaire
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      editFocusComment: false,
      editFocusLegalNotice: false,
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  props: {
    errorsCustom: {
      default: false,
    },
  },
  created() {
    if (!this.documentValidityList || this.documentValidityList.length == 0) {
      this.getDocumentValidityEnum({});
    }
    this.getQuoteOptionDefaultByQuoteId({});
  },
  computed: {
    ...mapGetters(["documentValidityList", "isLoadingDocumentValidityList"]),
    quoteOptionDefault: {
      get() {
        return this.$store.getters.quoteOptionDefaultForm;
      },
      set(value) {
        this.$store.commit("SET_QUOTE_OPTION_DEFAULT_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getDocumentValidityEnum",
      "getQuoteOptionDefaultByQuoteId",
    ]),
    // focusFunction(val, type) {
    //   if (type == "comment") {
    //     this.editFocusComment = val;
    //   } else if (type == "legalNotice") {
    //     this.editFocusLegalNotice = val;
    //   }
    // },
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
};
</script>
