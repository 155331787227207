<template lang="pug">
  validation-observer(ref='formInstitutionPreferences')
    b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
      h3.mb-2 Paramétrage par défaut de vos interventions
      b-row.my-0
          b-col.py-0.mb-1(cols='12')
              b-form-group(label='Évenement par défaut' label-for='payrollEventIdInterventionDefault')
              validation-provider(#default='{ errors }' name='payrollEventIdInterventionDefault')
                  v-select(:loading="isLoadingPayrollEventsList" :closeOnSelect="true" :state="errors.length > 0 ? false : null" v-model="institutionSettings.payrollEventIdInterventionDefault" :options="payrollEventsList" :reduce="elem => elem.id")
                      template(v-slot:no-options='')
                          template  Aucun r&eacute;sultat trouv&eacute;
      b-row.my-0
          b-col.py-0.mb-1(cols='12')
              b-form-group(label='Mission par défaut' label-for='missionIdInterventionDefault')
              validation-provider(#default='{ errors }' name='missionIdInterventionDefault')
                  v-select(:loading="isLoadingMissionsList" :deselectFromDropdown="true" :closeOnSelect="true" :state="errors.length > 0 ? false : null" v-model="institutionSettings.missionIdInterventionDefault" :options="missionsList" :reduce="elem => elem.id")
                      template(v-slot:no-options='')
                          template  Aucun r&eacute;sultat trouv&eacute;
  </template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";

export default {
  created() {
    this.getMissions({}), this.getPayrollEvents({});
  },
  computed: {
    ...mapGetters([
      "institutionSettings",
      "payrollEventsList",
      "isLoadingPayrollEventsList",
      "missionsList",
      "isLoadingMissionsList",
    ]),
    institutionSettings: {
      get() {
        return this.$store.getters.institutionSettings;
      },
      set(value) {
        this.$store.commit("SET_INSTITUTION_SETTINGS", value);
      },
    },
  },
  methods: {
    ...mapActions(["getMissions", "getPayrollEvents"]),
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
};
</script>
<style lang=""></style>
