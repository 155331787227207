<template lang="pug">
validation-observer(ref='formInstitutionPreferences')
  b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
    h3.mb-2 Paramétrage par défaut de vos bons de réception
    // Mettre le formulaire ici.
    b-row.my-0()
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Notes de bas de page' label-for='commentInvoiceDefault')
          validation-provider(#default='{ errors }' name='commentInvoiceDefault')
            quill-editor(v-model="receiptFormOptionDefault.comment" :options="editorOption" class="edit")
    vs-divider.mt-1.mb-1(color='#cccccc')
    h3 Options par défaut des bons de réception
    p.mb-2 Ces options seront appliquées par défaut à tous vos prochains bons de réception.
    .d-flex.flex-wrap
      .pr-2.checkbox-column
        h4.primary.pb-50 Edition
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.showReferenceColumn")
              | Afficher la colonne des références
      .pr-2.checkbox-column
        h4.primary.pb-50 Prévisualisation
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideInstitutionLogo")
              | Masquer mon logo
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideInstitutionName")
              | Masquer ma raison sociale
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideInstitutionAddress")
              | Masquer mon adresse
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideAffair")
              | Masquer l'affaire
          .d-flex.w-100.mb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showAffairCode" :disabled="receiptFormOptionDefault.hideAffair")
              | Masquer le code de l'affaire
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideStorageLocation")
              | Masquer le point de stockage
      .pr-2.checkbox-column
        h4.primary.pb-50 Document
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideUser")
              | Masquer le contact
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideCollaborator")
              | Masquer le collaborateur
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideIndexColumn")
              | Masquer la colonne des index
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideReceivedQuantityColumn")
              | Masquer la colonne des quantités reçues
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideOrderedQuantityColumn")
              | Masquer la colonne des quantités commandées
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hidePreviousQuantitiesReceived")
              | Masquer la colonne des quantités déjà reçues
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideUnitColumn")
              | Masquer la colonne des unités
      .checkbox-column
        h4.primary.pb-50 Client
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideCompanyCustomer")
              | Masquer le client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyCustomerCode" :disabled="receiptFormOptionDefault.hideCompanyCustomer")
              | Afficher le code du client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyCustomerAddressLabel" :disabled="receiptFormOptionDefault.hideCompanyCustomer")
              | Afficher le libellé de l'adresse
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyCustomerMail" :disabled="receiptFormOptionDefault.hideCompanyCustomer")
              | Afficher l'adresse mail
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyCustomerPhoneNumber" :disabled="receiptFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de téléphone
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyCustomerSiret" :disabled="receiptFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de SIRET
          .d-flex.w-100.mb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyCustomerTvaNumber" :disabled="receiptFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de TVA intracommunautaire
          h4.primary.mb-1 Fournisseur
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="receiptFormOptionDefault.hideCompanyProvider")
              | Masquer le fournisseur
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyProviderCode" :disabled="receiptFormOptionDefault.hideCompanyProvider")
              | Afficher le code du fournisseur
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyProviderAddressLabel" :disabled="receiptFormOptionDefault.hideCompanyProvider")
              | Afficher le libellé de l'adresse
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyProviderMail" :disabled="receiptFormOptionDefault.hideCompanyProvider")
              | Afficher l'adresse mail
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyProviderPhoneNumber" :disabled="receiptFormOptionDefault.hideCompanyProvider")
              | Afficher le numéro de téléphone
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyProviderSiret" :disabled="receiptFormOptionDefault.hideCompanyProvider")
              | Afficher le numéro de SIRET
          .d-flex.w-100.sub-checkbox
            b-form-checkbox(v-model="receiptFormOptionDefault.showCompanyProviderTvaNumber" :disabled="receiptFormOptionDefault.hideCompanyProvider")
              | Afficher le numéro de TVA intracommunautaire
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  data() {
    return {
      editFocusComment: false,
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  created() {
    this.getReceiptFormOptionDefaultByReceiptFormId({});
  },
  computed: {
    ...mapGetters(["documentValidityList", "isLoadingDocumentValidityList"]),
    receiptFormOptionDefault: {
      get() {
        return this.$store.getters.receiptFormOptionDefaultForm;
      },
      set(value) {
        this.$store.commit("SET_RECEIPT_FORM_OPTION_DEFAULT_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["getReceiptFormOptionDefaultByReceiptFormId"]),
    // focusFunction(val, type) {
    //   if (type == "comment") {
    //     this.editFocusComment = val;
    //   }
    // },
  },
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
<style lang=""></style>
