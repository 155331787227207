<template lang="pug"> 
  div
    b-modal#modal-update-company-account-export.modal-new-document(ok-only ok-title="Sauvegarder" size="lg" ref='modal-update-company-account-export' title='Comptes clients' @ok='handleOk')
      .justify-content-center(v-if="isUpdatingCompany")
        .content-loader-center.m-0(style="height:250px")
          .text-center.flex-center
            .loading-bg-inner(variant="primary" style="width: 3rem; height: 3rem;")
              .loader
                .outer
                .middle
                .inner
            span(style="margin-top: 8rem") Mise à jour des comptes clients...
      div.justify-content-center(v-else)
        validation-observer(style="flex:1" ref="updateCompanyAccountExportForm")
          .d-flex.align-items-end(v-for="(element,index) of dataToUpdate")
            b-row.my-0.w-100
              b-col.py-0.mb-1.d-flex.flex-column(cols='6')
                label.w-100(label-for='name' v-if="index == 0") Client
                b-form-group.w-100
                  b-form-input#name.w-100.not-autocomplete(disabled type="text" v-model='element.name' autocomplete='nope' aria-autocomplete='nope')
              b-col.py-0.mb-1.d-flex.flex-column(cols='6')
                label.w-100(label-for='companyAccount' v-if="index == 0") Compte auxiliaire *
                b-form-group.w-100
                  validation-provider(#default='{ errors }' name='companyAccount' rules="required")
                    b-form-input#companyAccount.w-100.not-autocomplete(type="text" v-model='element.companyAccount' autocomplete='nope' aria-autocomplete='nope')
                    small.text-danger {{ errors[0] }}
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    dataToUpdate: [],
  }),
  computed: {
    ...mapGetters(["isUpdatingCompany"]),
  },
  methods: {
    ...mapActions(["updateCompaniesAccount"]),
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      this.$refs.updateCompanyAccountExportForm
        .validate()
        .then(async (success) => {
          if (success) {
            let companiesAccount = this.dataToUpdate.map((element) => {
              return {
                item1: element.id,
                item2: element.companyAccount,
              };
            });
            this.updateCompaniesAccount({ companiesAccount: companiesAccount });
            this.$nextTick(() => {
              this.$refs["modal-update-company-account-export"].toggle(
                "#toggle-btn-update-company-account-export"
              );
            });
          }
        });
    },
    popupUpdateCompanyAccountExportEvent(array) {
      this.dataToUpdate = structuredClone(array);
      this.$bvModal.show("modal-update-company-account-export");
    },
  },
};
</script>
