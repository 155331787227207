<template lang="pug">
validation-observer(ref='formInstitutionLegalNotice')
  b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
    h3 Pieds de page des documents
    b-row.my-0
      b-col.py-0.mb-1(cols='12')
        b-form-group.pr-1(label-for='footerType' style='flex:1')
          validation-provider(#default='{ errors }' name='footerType')
            BFormRadio.mb-1(v-model="institutionSettings.footerType" :value="0" @input="generateFooter") Texte généré automatiquement
            BFormRadio(v-model="institutionSettings.footerType" :value="1") Texte libre
    b-row.my-0(@mouseover="mouseOn = true" @mouseleave="mouseOn = false")
      b-col.py-0.mb-1(cols='12')
        b-form-group.pr-1(label-for='commentQuoteDefault' style='flex:1')
          validation-provider(#default='{ errors }' name='commentQuoteDefault')
            quill-editor(:disabled='institutionSettings.footerType == 0' v-model="institutionSettings.footer" :options="editorOption" class="edit")
    vs-divider.mt-1.mb-1(color='#cccccc')
    h3 Conditions générales de vente
    b-row.my-0
      b-col.py-0.mb-1(cols='12')
        div(@mouseover="mouseOnCGV = true" @mouseleave="mouseOnCGV = false")
          b-form-group.mt-1(label-for='description')
            quill-editor.quill-editor-scroll(v-model="institutionSettings.termsOfSales" :options="editorOption" class="edit editor-cgv")
    b-row.my-0
      div()
        b-row.my-0.ml-1 
          b-col.py-0.mb-1(cols='12')
            b-form-group.pr-1(label-for='showTermsOfSales' style='flex:1')
              validation-provider(#default='{ errors }' name='showTermsOfSales')
                  BFormCheckbox(v-model="quoteOptionDefault.showTermsOfSales")
                    | Afficher sur les devis
        b-row.my-0.ml-1
          b-col.py-0.mb-1(cols='12')
            b-form-group.pr-1(label-for='showTermsOfSalesInvoice' style='flex:1')
              validation-provider(#default='{ errors }' name='showTermsOfSalesInvoice')
                  BFormCheckbox(v-model="invoiceOptionDefault.showTermsOfSales")
                    | Afficher sur les factures
</template>

<script>
import { BFormInput, BFormGroup, BFormCheckbox, BButton } from "bootstrap-vue";
import { debounce } from "lodash";
import vSelect from "vue-select";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { mask } from "vue-the-mask";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import {
  guaranteeTypeTranslate,
  legalFormTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      radio: "next",
      mouseOn: false,
      editFocus: false,
      mouseOnCGV: false,
      editFocusCGV: false,
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "institutionSelected",
      "legalFormList",
      "guaranteeTypeList",
      "institutionSettingsActive",
    ]),
    quoteOptionDefault: {
      get() {
        return this.$store.getters.quoteOptionDefaultForm;
      },
      set(value) {
        this.$store.commit("SET_QUOTE_OPTION_DEFAULT_FORM", value);
      },
    },
    invoiceOptionDefault: {
      get() {
        return this.$store.getters.invoiceOptionDefaultForm;
      },
      set(value) {
        this.$store.commit("SET_INVOICE_OPTION_DEFAULT_FORM", value);
      },
    },
    institutionSettings: {
      get() {
        return this.$store.getters.institutionSettings;
      },
      set(value) {
        this.$store.commit("SET_INSTITUTION_SETTINGS", value);
      },
    },
  },
  methods: {
    guaranteeTypeTranslate,
    legalFormTranslate,
    // focusFunction(val) {
    //   if (this.institutionSettings.footerType == 1) this.editFocus = val;
    // },
    focusFunctionCGV(val) {
      this.editFocusCGV = val;
    },
    generateFooter(value) {
      if (value == 0) {
        let name = this.institutionSelected.name
          ? this.institutionSelected.name
          : "";
        let address = this.institutionSelected.address
          ? this.institutionSelected.address
          : "";
        let addressComplement = this.institutionSelected.addressComplement
          ? this.institutionSelected.addressComplement
          : "";
        let zipCode = this.institutionSelected.zipCode
          ? this.institutionSelected.zipCode
          : "";
        let city = this.institutionSelected.city
          ? this.institutionSelected.city
          : "";
        let country = this.institutionSelected.country
          ? this.institutionSelected.country
          : "";
        let legalForm = this.institutionSelected.legalForm
          ? legalFormTranslate(this.institutionSelected.legalForm)
          : "";
        let capital = this.institutionSelected.capital
          ? this.institutionSelected.capital
          : "";
        let phone = this.institutionSelected.phoneNumber
          ? this.institutionSelected.phoneNumber
          : "";
        let email = this.institutionSelected.email
          ? "- " + this.institutionSelected.email
          : "";
        let apeCode = this.institutionSelected.apeCode
          ? this.institutionSelected.apeCode
          : "";
        let rcsCity = this.institutionSelected.rcsCity
          ? this.institutionSelected.rcsCity
          : "";
        let registrationNumber = this.institutionSelected.registrationNumber;
        let website = this.institutionSelected.website
          ? "- " + this.institutionSelected.website
          : "";
        let guaranteeType = this.institutionSelected.guaranteeType
          ? guaranteeTypeTranslate(this.institutionSelected.guaranteeType)
          : "";
        let insuranceName = this.institutionSelected.insuranceName
          ? this.institutionSelected.insuranceName
          : "";
        let insuranceAddress = this.institutionSelected.insuranceAddress
          ? this.institutionSelected.insuranceAddress
          : "";
        let insuranceAddressComplement = this.institutionSelected
          .insuranceAddressComplement
          ? this.institutionSelected.insuranceAddressComplement
          : "";
        let insuranceZipCode = this.institutionSelected.insuranceZipCode
          ? this.institutionSelected.insuranceZipCode
          : "";
        let insuranceCity = this.institutionSelected.insuranceCity
          ? this.institutionSelected.insuranceCity
          : "";
        let insuranceCountry = this.institutionSelected.insuranceCountry
          ? this.institutionSelected.insuranceCountry
          : "";
        let insuranceCoverage = this.institutionSelected.insuranceCoverage
          ? this.institutionSelected.insuranceCoverage
          : "";
        let siret = this.institutionSelected.siret
          ? this.institutionSelected.siret
          : "";

        this.institutionSettings.footer =
          `<div style="text-align: center; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">
        <p style="margin: 0px; padding: 0px;">${name} - ${address} ${addressComplement}, ${zipCode} ${city}, ${country}</p>
        <p style="margin: 0px; padding: 0px;">${legalForm} au capital de ${capital} €</p>
        <p style="margin: 0px; padding: 0px;">${siret} - RCS ${rcsCity} - APE: ${apeCode}</p>` +
          (registrationNumber
            ? `<p style="margin: 0px; padding: 0px;">Immatriculé au Répertoire des métiers sous le N° ${registrationNumber}</p>`
            : ``) +
          `<p style="margin: 0px; padding: 0px;">${phone} ${email} ${website}</p>
        <p style="margin: 0px; padding: 0px;">Garantie ${guaranteeType} - ${insuranceName} - ${insuranceAddress} ${insuranceAddressComplement}, ${insuranceZipCode} ${insuranceCity}, ${insuranceCountry}</p>
        <p style="margin: 0px; padding: 0px;">Couverture: ${insuranceCoverage}</p>
        </div>`;
      }
      // else {
      //   this.institutionSettings.footer = this.institutionSettingsActive.footer;
      // }
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BFormGroup,
    VueTypeaheadBootstrap,
    BFormCheckbox,
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
<style>
.editor-cgv .ql-editor {
  height: 500px !important;
}
</style>
