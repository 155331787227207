<template lang="pug">
validation-observer(ref='formInstitutionPreferences')
  b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
    h3.mb-2 Paramétrage par défaut de vos bons de commandes
    // Mettre le formulaire ici.
    b-row.my-0
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Condition de paiement *' label-for='paymentConditionOrderFormDefaultId' style='flex:1')
          validation-provider(#default='{ errors }' name='paymentConditionOrderFormDefaultId' rules="required")
            v-select#paymentConditionOrderFormDefaultId(:class='!orderFormOptionDefault.paymentConditionDefaultId && errorsCustom ? "style-chooser": ""' :loading='isLoadingPaymentConditionsList' :deselectFromDropdown='true' :closeOnSelect='true' :state="errors.length > 0 ? false : null" v-model='orderFormOptionDefault.paymentConditionDefaultId' :options='paymentConditionsList' :reduce='elem => elem.id')
              template(v-slot:no-options='')
                template  Aucune condition trouv&eacute;
            small(class="text-danger") {{ errors[0] }}
    b-row.my-0()
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Notes de bas de page' label-for='commentOrderFormDefault')
          validation-provider(#default='{ errors }' name='commentOrderFormDefault')
            quill-editor(v-model="orderFormOptionDefault.comment" :options="editorOption" class="edit")
    vs-divider.mt-1.mb-1(color='#cccccc')
    h3 Options par défaut des bons de commandes
    p.mb-2 Ces options seront appliquées par défaut à tous vos prochains bons de commandes.
    .d-flex.flex-wrap
      .pr-2.checkbox-column
        h4.primary.pb-50 Edition
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.showReferenceColumn")
              | Afficher la colonne des références
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.isReverseCharge")
              | Activer l'autoliquidation
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.showDiscountColumn")
              | Afficher la colonne des remises par ligne
      .pr-2.checkbox-column
        h4.primary.pb-50 Prévisualisation
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideInstitutionLogo")
              | Masquer mon logo
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideInstitutionName")
              | Masquer ma raison sociale
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideInstitutionAddress")
              | Masquer mon adresse
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideAffair")
              | Masquer l'affaire
          .d-flex.w-100.mb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showAffairCode" :disabled="orderFormOptionDefault.hideAffair")
              | Masquer le code de l'affaire
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideStorageLocation")
              | Masquer le point de stockage
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideTotal")
              | Masquer le total
      .pr-2.checkbox-column
        h4.primary.pb-50 Document
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideUser")
              | Masquer le contact
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideIndexColumn")
              | Masquer la colonne des index
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideUnitColumn")
              | Masquer la colonne des unités
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideUnitPriceHtColumn")
              | Masquer la colonne des prix unitaire HT
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideReferencielTvaColumn")
              | Masquer la colonne des TVA
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hidePriceHtColumn")
              | Masquer la colonne des totaux HT
      .checkbox-column
        h4.primary.pb-50 Client
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideCompanyCustomer")
              | Masquer le client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyCustomerCode" :disabled="orderFormOptionDefault.hideCompanyCustomer")
              | Afficher le code du client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyCustomerAddressLabel" :disabled="orderFormOptionDefault.hideCompanyCustomer")
              | Afficher le libellé de l'adresse
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyCustomerMail" :disabled="orderFormOptionDefault.hideCompanyCustomer")
              | Afficher l'adresse mail
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyCustomerPhoneNumber" :disabled="orderFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de téléphone
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyCustomerSiret" :disabled="orderFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de SIRET
          .d-flex.w-100.mb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyCustomerTvaNumber" :disabled="orderFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de TVA intracommunautaire
          h4.primary.mb-1 Fournisseur
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="orderFormOptionDefault.hideCompanyProvider")
              | Masquer le fournisseur
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyProviderCode" :disabled="orderFormOptionDefault.hideCompanyProvider")
              | Masquer le code du fournisseur
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyProviderAddressLabel" :disabled="orderFormOptionDefault.hideCompanyProvider")
              | Afficher le libellé de l'adresse
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyProviderMail" :disabled="orderFormOptionDefault.hideCompanyProvider")
              | Afficher l'adresse mail
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyProviderPhoneNumber" :disabled="orderFormOptionDefault.hideCompanyProvider")
              | Afficher le numéro de téléphone
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyProviderSiret" :disabled="orderFormOptionDefault.hideCompanyProvider")
              | Afficher le numéro de SIRET
          .d-flex.w-100.sub-checkbox
            b-form-checkbox(v-model="orderFormOptionDefault.showCompanyProviderTvaNumber" :disabled="orderFormOptionDefault.hideCompanyProvider")
              | Afficher le numéro de TVA intracommunautaire
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  props: {
    errorsCustom: {
      default: false,
    },
  },
  data() {
    return {
      editFocusComment: false,
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  created() {
    if (!this.paymentConditionsList || this.paymentConditionsList.length == 0) {
      this.getPaymentConditionByWorkspaceId({});
    }
    this.getOrderFormOptionDefaultByOrderFormId({});
  },
  computed: {
    ...mapGetters(["paymentConditionsList", "isLoadingPaymentConditionsList"]),
    orderFormOptionDefault: {
      get() {
        return this.$store.getters.orderFormOptionDefaultForm;
      },
      set(value) {
        this.$store.commit("SET_ORDER_FORM_OPTION_DEFAULT_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getPaymentConditionByWorkspaceId",
      "getOrderFormOptionDefaultByOrderFormId",
    ]),
    // focusFunction(val, type) {
    //   if (type == "comment") {
    //     this.editFocusComment = val;
    //   }
    // },
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
};
</script>
<style lang=""></style>
