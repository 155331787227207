<template lang="pug">
.p-0
  .content-loader-center.m-0.h-100(v-if=" isCreatingInstitution || isUpdatingInstitution || isCreatingBankAccount || isLoadingInstitutionSettings || isCreatingQuoteDownPaymentsRequest || isUpdatingQuoteDownPaymentsRequest || isDeletingQuoteDownPaymentsRequest || isLoadingQuoteDownPaymentsRequestsList || isCreatingBankAccount || isUpdatingBankAccount || isDeletingBankAccount || isLoadingBankAccountsList")
    .content-loader-center.m-0.h-100
      .text-center.flex-center
        .loading-bg-inner(role='status')
          .loader
            .outer
            .middle
            .inner
  div.details-container(v-else)
    div.d-flex.content-scrollable-sticky.h-100.justify-content-start.w-100
      #nav-sticky-vertical.text-center.h-100(ref="navStickyVertical" style='background: #EEF1F6; width: 240px; min-width: 240px; max-width: 240px; position: relative; top: 0; overflow-y: auto; overflow-x: hidden; transition: all 0.5s ease-in-out;')
        .close-btn.d-flex.justify-content-end.pt-1.px-1
          span.material-icons-outlined(@click="closeMenuSidebar" style="cursor: pointer;")
            | chevron_left
        .head
          .d-flex.justify-content-between.px-1
            div
              h3.text-nowrap {{ $route.meta.pageTitle }}
          .d-flex.justify-content-between.pb-1.px-1
            .d-flex
              //- Status(:statusId="document.status" :nature="document.nature")
              span.text-nowrap(style="white-space: break-spaces;") {{institutionForm.name}}
            .end
        ul
          li.title
            vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
              span.text-primary Général
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'general' ? 'current' : ''"  @click="activeTab('general')")
              span.material-icons-outlined.text-primary
                | corporate_fare
              span.pl-1.text-nowrap Informations générales
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="institutionForm.id==0 ? 'disabled' : tabActive == 'preferences' ? 'current' : ''"  @click="activeTab('preferences')")
              span.material-icons-outlined.text-primary
                | assignment
              span.pl-1.text-nowrap Préférences
          li.title
            vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
              span.text-primary Documents
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(75, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'quotes' ? 'current' : ''"  @click="activeTab('quotes')")
              span.material-icons-outlined.text-primary
                | difference
              span.pl-1.text-nowrap Devis
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(108, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'orderFormCustomer' ? 'current' : ''"  @click="activeTab('orderFormCustomer')")
              span.material-icons-outlined.text-primary
                | checklist_rtl
              span.pl-1.text-nowrap Commandes client
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(66, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'invoices' ? 'current' : ''"  @click="activeTab('invoices')")
              span.material-icons-outlined.text-primary
                | description
              span.pl-1.text-nowrap Factures
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(51, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'credit' ? 'current' : ''"  @click="activeTab('credit')")
              span.material-icons-outlined.text-primary
                | difference
              span.pl-1.text-nowrap Avoirs
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(24, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'orderForm' ? 'current' : ''"  @click="activeTab('orderForm')")
              span.material-icons-outlined.text-primary
                | insert_drive_file
              span.pl-1.text-nowrap Commandes fournisseur
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(30, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'receiptForm' ? 'current' : ''"  @click="activeTab('receiptForm')")
              span.material-icons-outlined.text-primary
                | download
              span.pl-1.text-nowrap Bons de réception
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(12, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'deliveryForm' ? 'current' : ''"  @click="activeTab('deliveryForm')")
              span.material-icons-outlined.text-primary
                | upload
              span.pl-1.text-nowrap Bons de sortie
          li.title
            vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
              span.text-primary Paramètres
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(121, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'intervention' ? 'current' : ''"  @click="activeTab('intervention')")
              span.material-icons-outlined.text-primary
                | construction
              span.pl-1.text-nowrap Interventions
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(69, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'payments' ? 'current' : ''"  @click="activeTab('payments')")
              span.material-icons-outlined.text-primary
                | payments
              span.pl-1.text-nowrap Paiements
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(75, 'all') || checkFunctionUser(51, 'all') || checkFunctionUser(66, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'legal' ? 'current' : ''"  @click="activeTab('legal')")
              span.material-icons-outlined.text-primary
                | gavel
              span.pl-1.text-nowrap Mentions légales
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(v-if="checkFunctionUser(128, 'all')" :class="institutionForm.id==0 ? 'disabled' : tabActive == 'comptability' ? 'current' : ''"  @click="activeTab('comptability')")
              span.material-icons-outlined.text-primary
                | calculate
              span.pl-1.text-nowrap Comptabilité
      #content-wrapper.d-flex.flex-column.h-100(style="width: calc(100% - 240px)" ref="contentWrapper")
        .h-100.w-100
          validation-observer(ref='formInstitution')
              institutionGeneral#institutionGeneral(:institutionForm="institutionForm" v-show="tabActive=='general'")
              InstitutionPreferencesSettings(:errorsCustom="errorsCustom" v-show="tabActive=='preferences'")
              InstitutionQuoteSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(75, 'all')" v-show="tabActive=='quotes'")
              InstitutionOrderFormCustomerSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(108, 'all')" v-show="tabActive=='orderFormCustomer'")
              InstitutionInvoiceSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(66, 'all')" v-show="tabActive=='invoices'")
              InstitutionPaymentsSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(69, 'all')" v-show="tabActive=='payments'")
              InstitutionLegalNoticeSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(75, 'all') || checkFunctionUser(51, 'all') || checkFunctionUser(66, 'all')" v-show="tabActive=='legal'")
              InstitutionComptabilitySettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(128, 'all')" v-show="tabActive=='comptability'")
              InstitutionCreditSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(51, 'all')" v-show="tabActive=='credit'")
              InstitutionInterventionSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(121, 'all')" v-show="tabActive=='intervention'")
              InstitutionDeliveryFormSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(12, 'all')" v-show="tabActive=='deliveryForm'")
              InstitutionReceiptFormSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(30, 'all')" v-show="tabActive=='receiptForm'")
              InstitutionOrderFormSettings(:errorsCustom="errorsCustom" v-if="checkFunctionUser(24, 'all')" v-show="tabActive=='orderForm'")
          .d-flex.w-100.my-0
              .d-flex.flex-column.w-100
                  .d-flex.justify-content-between.px-1(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem;')
                      .d-flex.w-100
                          b-button.d-lg-flex.d-none(v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant='outline-primary' @click='cancel()')
                              feather-icon(icon='ArrowLeftIcon')
                              | Annuler
                          b-button.d-lg-none.d-flex(v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant='outline-primary' @click='cancel()')
                              feather-icon(icon='ArrowLeftIcon')
                      b-button-group
                          b-button.btn-invoice-builder-header.m-0.text-nowrap(@click="institutionForm.id ? update($event, false) : create($event)" variant='primary')
                              | {{ institutionForm.id ? 'Enregistrer' : 'Créer ma société' }}
                          b-button.btn-invoice-builder-header.bl-white(v-if="institution.id" @click="update($event, true)" style="border-left: 1px solid #ccc !important;" variant='primary')
                              feather-icon(icon='LogOutIcon')
</template>
<script>
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

import { InstitutionModel } from "@/types/api-orisis/models/InstitutionModel";
import { checkFunctionUser } from "@/auth/utils.ts";

import institutionGeneral from "@/components/institution/InstitutionGeneral.vue";
import InstitutionComptabilitySettings from "@/components/institutionSettings/InstitutionComptabilitySettings.vue";
import InstitutionCreditSettings from "@/components/institutionSettings/InstitutionCreditSettings.vue";
import InstitutionInterventionSettings from "@/components/institutionSettings/InstitutionInterventionSettings.vue";
import InstitutionInvoiceSettings from "@/components/institutionSettings/InstitutionInvoiceSettings.vue";
import InstitutionLegalNoticeSettings from "@/components/institutionSettings/InstitutionLegalNoticeSettings.vue";
import InstitutionOrderFormCustomerSettings from "@/components/institutionSettings/InstitutionOrderFormCustomerSettings.vue";
import InstitutionPaymentsSettings from "@/components/institutionSettings/InstitutionPaymentsSettings.vue";
import InstitutionPreferencesSettings from "@/components/institutionSettings/InstitutionPreferencesSettings.vue";
import InstitutionQuoteSettings from "@/components/institutionSettings/InstitutionQuoteSettings.vue";
import InstitutionDeliveryFormSettings from "@/components/institutionSettings/InstitutionDeliveryFormSettings.vue";
import InstitutionReceiptFormSettings from "@/components/institutionSettings/InstitutionReceiptFormSettings.vue";
import InstitutionOrderFormSettings from "@/components/institutionSettings/InstitutionOrderFormSettings.vue";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Ce mois-ci": [
    new Date(dayjs().startOf("month").hour(12)),
    new Date(dayjs().endOf("month")),
  ],
  "Ce trimestre": [
    new Date(dayjs().startOf("quarter").hour(12)),
    new Date(dayjs().endOf("quarter")),
  ],
  "Cette annéee": [
    new Date(dayjs().startOf("year").hour(12)),
    new Date(dayjs().endOf("year")),
  ],
  "Année prochaine": [
    new Date(dayjs().add(1, "year").startOf("year").hour(12)),
    new Date(dayjs().add(1, "year").endOf("year")),
  ],
  "Année dernière": [
    new Date(dayjs().subtract(1, "year").startOf("year").hour(12)),
    new Date(dayjs().subtract(1, "year").endOf("year")),
  ],
};
export default {
  props: {
    id: {
      default: null,
    },
  },
  data() {
    return {
      scrolled: false,
      errorsCustom: false,
      required,
      url,
      email,
      dataOrigine: {},
      institutionForm: InstitutionModel,
      tabActive: "general",
    };
  },
  async created() {
    this.popupInstitutionEvent(this.id);
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    ...mapActions([
      "updateInstitutionSettingPaymentCondition",
      "updateQuoteOptionDefault",
      "updateInvoiceOptionDefault",
      "updateCreditOptionDefault",
      "updateOrderFormOptionDefault",
      "updateReceiptFormOptionDefault",
      "updateDeliveryFormOptionDefault",
      "updateOrderFormCustomerOptionDefault",
      "createInstitution",
      "updateInstitution",
      "archiveInstitutions",
      "getInstitution",
      "createQuoteDownPaymentsRequest",
      "updateQuoteDownPaymentsRequest",
      "updateInstitutionSetting",
      "updatePaymentMethodsDefaults",
      "updateInstitutionSettingReferencielTvas",
      "getInstitutionTypes",
      "updateInstitutionTypeInstitutionSetting",
      "createBankAccount",
      "updateBankAccount",
    ]),
    checkFunctionUser,
    activeTab(tab) {
      if (this.institutionForm.id == 0 || this.institutionForm.id == null)
        return false;
      else this.tabActive = tab;
    },
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    closeMenuSidebar() {
      this.$refs.navStickyVertical.classList.toggle("sidebar-closed");
      this.$refs.contentWrapper.classList.toggle("sidebar-is-closed");
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });

      elements = document.querySelectorAll(".not-autocomplete");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },

    popupInstitutionEvent(id) {
      this.getInstitutionTypes({});
      if (id !== "" && id !== null && id !== 0) {
        this.getInstitution({}).then((res) => {
          this.institutionForm = JSON.parse(JSON.stringify(res));
          this.dataOrigine = JSON.stringify(this.institutionForm);
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.institutionForm = InstitutionModel;
      this.dataOrigine = JSON.stringify(this.institutionForm);
    },
    update(e, close) {
      e.preventDefault();
      this.$refs.formInstitution
        .validate()
        .then(async (success1) => {
          if (success1) {
            Promise.all(
              [
                this.updateInstitution({
                  institution: this.institutionForm,
                }),
                this.updateInstitutionSetting({
                  institutionSettings: {
                    ...this.institutionSettings,
                    institutionId: this.institutionForm.id,
                  },
                }),
                this.updateInstitutionSettingReferencielTvas({
                  institutionSettingId: this.institutionSettings.id,
                  referencielTvas: this.institutionSettings.referencielTvas
                    .filter((elem) => elem.referencielTva)
                    .map((elem) => {
                      return {
                        ...elem,
                        referencielTvaId: elem.referencielTva.id,
                      };
                    }),
                }),
                this.updateInstitutionTypeInstitutionSetting({
                  institutionTypeIds:
                    this.institutionSettings.institutionTypes.map((elem) => {
                      return elem.id;
                    }),
                  institutionSettingId: this.institutionSettings.id,
                }),
                this.updatePaymentMethodsDefaults({
                  paymentMethodsDefaultIds:
                    this.institutionSettings.paymentMethodsDefaults.map(
                      (elem) => {
                        return elem.id;
                      }
                    ),
                  institutionSettingId: this.institutionSettings.id,
                }),
                this.checkFunctionUser(75, "all")
                  ? this.updateQuoteOptionDefault({
                      quoteOptionDefault: this.quoteOptionDefaultForm,
                    })
                  : null,
                this.checkFunctionUser(66, "all")
                  ? this.updateInvoiceOptionDefault({
                      invoiceOptionDefault: this.invoiceOptionDefaultForm,
                    })
                  : null,
                this.checkFunctionUser(51, "all")
                  ? this.updateCreditOptionDefault({
                      creditOptionDefault: this.creditOptionDefaultForm,
                    })
                  : null,
                this.checkFunctionUser(24, "all")
                  ? this.updateOrderFormOptionDefault({
                      orderFormOptionDefault: this.orderFormOptionDefaultForm,
                    })
                  : null,
                this.checkFunctionUser(30, "all")
                  ? this.updateReceiptFormOptionDefault({
                      receiptFormOptionDefault:
                        this.receiptFormOptionDefaultForm,
                    })
                  : null,
                this.checkFunctionUser(12, "all")
                  ? this.updateDeliveryFormOptionDefault({
                      deliveryFormOptionDefault:
                        this.deliveryFormOptionDefaultForm,
                    })
                  : null,
                this.checkFunctionUser(108, "all")
                  ? this.updateOrderFormCustomerOptionDefault({
                      orderFormCustomerOptionDefault:
                        this.orderFormCustomerOptionDefaultForm,
                    })
                  : null,
                this.institutionSettings?.orderFormPaymentConditions?.length > 0
                  ? this.updateInstitutionSettingPaymentCondition({
                      institutionSettingId: this.institutionSettings.id,
                      paymentConditions: [
                        {
                          paymentConditionId: this.institutionSettings
                            .invoicePaymentConditions[0].id
                            ? this.institutionSettings
                                .invoicePaymentConditions[0].id
                            : this.institutionSettings
                                .invoicePaymentConditions[0],
                          isPaymentConditionDefault: true,
                        },
                      ],
                      isOrderForm: false,
                    })
                  : null,
                this.institutionSettings?.orderFormPaymentConditions?.length > 0
                  ? this.updateInstitutionSettingPaymentCondition({
                      institutionSettingId: this.institutionSettings.id,
                      paymentConditions: [
                        {
                          paymentConditionId: this.institutionSettings
                            .orderFormPaymentConditions[0].id
                            ? this.institutionSettings
                                .orderFormPaymentConditions[0].id
                            : this.institutionSettings
                                .orderFormPaymentConditions[0],
                          isPaymentConditionDefault: true,
                        },
                      ],
                      isOrderForm: true,
                    })
                  : null,
              ]
                .concat(
                  this.institutionSettings.quoteDownPaymentsRequestDefaults.map(
                    (elem) => {
                      if (elem.id) {
                        return this.updateQuoteDownPaymentsRequest({
                          quoteDownPaymentsRequest: {
                            ...elem,
                            institutionSettingId: this.institutionSettings.id,
                          },
                        });
                      } else {
                        return this.createQuoteDownPaymentsRequest({
                          quoteDownPaymentsRequest: {
                            ...elem,
                            institutionSettingId: this.institutionSettings.id,
                          },
                        });
                      }
                    }
                  )
                )
                .concat(
                  this.bankAccountsListForm.map((elem) => {
                    if (elem.id) {
                      return this.updateBankAccount({
                        bankAccount: {
                          ...elem,
                          institutionId: this.institutionForm.id,
                        },
                      });
                    } else {
                      return this.createBankAccount({
                        bankAccount: {
                          ...elem,
                          institutionId: this.institutionForm.id,
                        },
                      });
                    }
                  })
                )
            ).then((res) => {
              if (close) {
                this.$nextTick(() => {
                  if (
                    this.$route.params.routeOrigine &&
                    this.$route.params.routeOrigine !== ""
                  ) {
                    this.$tabs.close();
                  } else {
                    this.$tabs.close({ to: "/institutions" });
                  }
                });
              }
            });
          } else {
            this.$bvToast.toast(
              "Un ou plusieurs champs sont requis pour l'enregistrement de vos données. Veuillez les remplir pour continuer.",
              {
                title: `Champs requis`,
                variant: "danger",
                solid: true,
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    create(e) {
      e.preventDefault();
      this.$refs.formInstitution
        .validate()
        .then(async (success1) => {
          if (success1) {
            this.createInstitution({
              institution: this.institutionForm,
            });
            this.$nextTick(() => {
              if (
                this.$route.params.routeOrigine &&
                this.$route.params.routeOrigine !== ""
              ) {
                this.$tabs.close();
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // Controls from
    archiveInstitutionLocal(id, name) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + name + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveInstitutions({
              institutionIds: [id],
            });
            this.$tabs.close();
          }
        });
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.institutionForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$tabs.close();
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "bankAccountsListForm",
      "deliveryFormOptionDefaultForm",
      "orderFormCustomerOptionDefaultForm",
      "quoteOptionDefaultForm",
      "receiptFormOptionDefaultForm",
      "invoiceOptionDefaultForm",
      "creditOptionDefaultForm",
      "orderFormOptionDefaultForm",
      "isCreatingInstitution",
      "isUpdatingInstitution",
      "isLoadingInstitutionSettings",
      "isCreatingBankAccount",
      "isUpdatingBankAccount",
      "isDeletingBankAccount",
      "isLoadingBankAccountsList",
      "isCreatingQuoteDownPaymentsRequest",
      "isUpdatingQuoteDownPaymentsRequest",
      "isDeletingQuoteDownPaymentsRequest",
      "isLoadingQuoteDownPaymentsRequestsList",
    ]),
    institution: {
      get() {
        return this.$store.getters.institution;
      },
      set(value) {
        this.$store.commit("SET_INSTITUTION", value);
      },
    },
    institutionSettings: {
      get() {
        return this.$store.getters.institutionSettings;
      },
      set(value) {
        this.$store.commit("SET_INSTITUTION_SETTINGS", value);
      },
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
    institutionGeneral,
    InstitutionPreferencesSettings,
    InstitutionQuoteSettings,
    InstitutionInvoiceSettings,
    InstitutionPaymentsSettings,
    InstitutionLegalNoticeSettings,
    InstitutionComptabilitySettings,
    InstitutionOrderFormCustomerSettings,
    InstitutionCreditSettings,
    InstitutionInterventionSettings,
    InstitutionDeliveryFormSettings,
    InstitutionReceiptFormSettings,
    InstitutionOrderFormSettings,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}

.sidebar-closed,
.sidebar-closed .title span {
  transition-delay: 0.5s;
  transition: all 0.5s ease-in-out;
}

.sidebar-closed .title span {
  font-size: 0;
}
.sidebar-closed .title .vs-divider {
  margin: 0 !important;
}

.sidebar-closed .close-btn span {
  transform: rotate(180deg);
}

.sidebar-closed .head,
.sidebar-closed .title {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.sidebar-closed {
  width: 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
}

#content-wrapper {
  transition: all 0.5s ease-in-out;
}

#content-wrapper.sidebar-is-closed {
  width: calc(100% - 52px) !important;
}

#nav-sticky-vertical,
.title span,
.head,
.title,
.title .vs-divider {
  transition: all 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    max-height: 100%;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
  to {
    max-height: 0%;
    width: 0%;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
