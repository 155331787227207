<template lang="pug">
  validation-observer(ref='formInstitutionLegalNotice')
    .p-1(autocomplete='off' id="export-account" style="height: calc(100% - 46px); overflow-y: auto;")
      h3 Exercices comptables
      .d-flex.w-100.my-0(v-for="(item, index) in fiscalYearList" :key="index")
          b-col.d-flex.align-items-center.mb-0(cols='12' @mouseover="lineHover = true" @mouseleave="lineHover = false")
              .d-flex.align-items-center(style="min-width:100px" :class="statusFiscalYear(item) =='Terminé' ? 'text-success' : 'text-primary'")
                  span.material-icons-outlined()
                      | {{ statusFiscalYear(item) =='Terminé' ? 'done' : 'hourglass_empty' }}
                  strong {{ statusFiscalYear(item) }}
              | Du&nbsp;
              strong {{ formatDate(item.startDate) }}
              | &nbsp;au&nbsp;
              strong {{ formatDate(item.endDate) }}
              feather-icon.cursor-pointer(v-if="statusFiscalYear(item) == 'A venir' && lineHover" icon="XIcon" size="18"  @click="deleteFiscalYearLocal(item, index)" v-b-tooltip.hover.top="'Supprimer'")
      vs-divider.mt-1.mb-1(color='#cccccc')
      .justify-content-center.h-100(v-if="isCreatingFiscalYear || isLoadingFiscalYearList")
            .content-loader-center.m-0.h-100
              .text-center.flex-center
                .loading-bg-inner(variant="primary" style="width: 3rem; height: 3rem;")
                  .loader
                    .outer
                    .middle
                    .inner
                .mt-5
                  br
                  br
                  br
                  |   Chargement des d&eacute;tails du bail...

      .d-flex.align-items-end(v-else)
        .d-flex.w-100.my-0.align-items-end
            .d-flex.flex-column.w-100.w-md-25.py-0.d-flex.align-items-end.mr-1.h-100
              label.w-100 Date de début *
              date-range-picker.w-100(:drops="'up'" :showDropdowns='true' :disabled="this.fiscalYearList.length>0" :minDate="lastEndDateFiscalYear" ref="startDateFiscalYear" id="startDateFiscalYear"  :show-week-numbers="true" :timePicker="false" :single-date-picker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="startDateFiscalYear" :auto-apply="true")
            .d-flex.flex-column.w-100.w-md-25.py-0.d-flex.align-items-end.mr-1.h-100
              label.w-100 Date de fin *
              date-range-picker.w-100(:drops="'up'" :showDropdowns='true' :minDate="startDateFiscalYearComputed" ref="endDateFiscalYear" id="endDateFiscalYear"  :show-week-numbers="true" :timePicker="false" :single-date-picker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="endDateFiscalYear" :auto-apply="true")
            b-button.d-flex.py-0.align-items-center.px-50(style="height: 32px" variant="primary" @click="createFiscalYearLocal")
              feather-icon(icon="PlusIcon")
              | Ajouter
      vs-divider.mt-1.mb-1(color='#cccccc')
      .d-flex.align-items-start()
        div(style="flex:1")
          h3 Compte complémentaire
          b-row.my-0 
            b-col.py-0.mb-1.d-flex.align-items-start.flex-column(cols='6')
              label.w-100 Retenue de garantie
              b-input(v-model="institutionSettings.holdBackAccount")
      vs-divider.mt-1.mb-1(color='#cccccc')
      .justify-content-center(v-if="isLoadingExport || isLoadingInvoicesCreditsOnTimeSpan" style="height: 100px;")
        .content-loader-center.m-0.h-100
          .text-center.flex-center
            .loading-bg-inner(variant="primary" style="width: 3rem; height: 3rem;")
              .loader
                .outer
                .middle
                .inner
      div(v-else-if="!isLoadingExport && institutionSettings.exportSalesJournals.length > 0")
        h3 Export comptable
        b-row.my-0
          b-col.py-0.mb-1(cols='4')
            b-row.my-0
              b-col.py-0.mb-1(cols='6')
                b-form-group(label="Période" label-for='accountingExportDuration')
                  validation-provider(#default='{ errors }' name='accountingExportDuration')
                    date-range-picker.w-100(ref="picker" :showDropdowns='true' id="accountingExportDuration" :show-week-numbers="true" :timePicker="false" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="filterRange" :auto-apply="true")
              b-col.py-0.mb-1(cols="6")
                b-dropdown.mt-2.dropdown-icon-wrapper(variant='primary' left='')
                  template(#button-content='')
                    span.mr-1 Export...
                  b-dropdown-item(v-for="(item, index) in institutionSettings.exportSalesJournals" :key="index" @click="generateAccountingExport(item.id)")
                    | {{ item.label }}
                  b-dropdown-item(@click="$router.push('/sales-journal')")
                    | Journal de vente
          b-col.py-0.mb-1.row-stats(cols='4' v-if="exportAccounting")
            .card-stats(:class="exportAccounting.inequalNumberText == null ? 'success' : 'danger'")
              .d-flex.align-items-center
                div
                  .icon.flex
                    span(class="material-icons-outlined text-white" style='font-size: 50px;')
                      | {{ exportAccounting.inequalNumberText == null ? 'done' : 'close' }}
              .card-stats-title
                .libele
                  | {{ exportAccounting.inequalNumberText != null ? exportAccounting.inequalNumberText : 'Les montants sont équilibrés' }}
          b-col.py-0.mb-1.row-stats(cols='4' v-if="exportAccounting")
            .card-stats(:class="exportAccounting.missingAccountText == null ? 'success' : 'danger'")
              .d-flex.align-items-center
                div
                  .icon.flex
                    span(class="material-icons-outlined text-white" style='font-size: 50px;')
                      | {{ exportAccounting.missingAccountText == null ? 'done' : 'close' }}
              .card-stats-title
                .libele
                  | {{ exportAccounting.missingAccountText != null ? exportAccounting.missingAccountText : 'Tous les comptes sont renseignés' }}
        b-row.my-0(v-if='exportAccounting && exportAccounting.exports')
          b-col.py-0.mb-1(cols="12")
            div
              #exportAccounting
                .exportAccounting-head(style="border-bottom:black 1px solid;")
                  div.text-left(style="width:20%")
                    | Date
                  div.text-left(style="width:20%")
                    | Client
                  div.text-left(style="width:20%")
                    | Compte
                  div.text-left(style="width:20%")
                    | Libellé
                  div.text-right(style="width:10%")
                    | Débit
                  div.text-right(style="width:10%")
                    | Crédit
                .exportAccounting-content.flex-wrap(v-for='(item, index) in exportAccounting.exports' :key='index')
                  .exportAccounting-content-children.w-100(:class="item.companyAccount == '#N/A' || item.isInequal ? 'text-danger' : ''")
                    div.text-left(style="width:20%")
                      | {{ formatDate(item.documentDate) }}
                    div.text-left(style="width:20%")
                      | {{ item.companyName }}
                    div.text-left(style="width:20%")
                      | {{ item.companyAccount }}
                    div.text-left(style="width:20%")
                      | {{ item.documentReference }}
                    div.text-right(style="width:10%")
                      | {{ item.nature == 1 || item.nature == 2 || item.nature == 3 || item.nature == 4 ? formatCurrency(item.totalTTC) : '' }}
                    div.text-right(style="width:10%")
                      | {{ item.nature == 1 || item.nature == 2 || item.nature == 3 || item.nature == 4 ? '' : formatCurrency(item.totalTTC) }}
                  .exportAccounting-content-children.w-100(v-for='(itemLine, indexLine) in item.accountExports' :key='indexLine' :class="item.isInequal ? 'text-danger' : ''")
                    div.text-left(style="width:20%")
                      | {{ formatDate(item.documentDate) }}
                    div.text-left(style="width:20%")
                      | {{ item.companyName }}
                    div.text-left(style="width:20%")
                      span {{itemLine.accountNumber}}
                    div.text-left(style="width:20%")
                      | {{ item.documentReference }}
                    div.text-right(style="width:10%")
                      | {{ item.nature == 1 || item.nature == 2 || item.nature == 3 || item.nature == 4 ? '' : formatCurrency(itemLine.value) }}
                    div.text-right(style="width:10%")
                      | {{ item.nature == 1 || item.nature == 2 || item.nature == 3 || item.nature == 4 ? formatCurrency(itemLine.value) : '' }}
                  .exportAccounting-content-children.w-100(v-if="item.holdbackNumber" :class="item.companyAccount == '#N/A' || item.isInequal ? 'text-danger' : ''")
                    div.text-left(style="width:20%")
                      | {{ formatDate(item.documentDate) }}
                    div.text-left(style="width:20%")
                      | {{ item.companyName }}
                    div.text-left(style="width:20%")
                      | {{ item.holdBackAccount }}
                    div.text-left(style="width:20%")
                      | {{ item.documentReference }}
                    div.text-right(style="width:10%")
                      | {{ item.nature == 1 || item.nature == 2 || item.nature == 3 || item.nature == 4 ? formatCurrency(item.holdbackNumber) : '' }}
                    div.text-right(style="width:10%")
                      | {{ item.nature == 1 || item.nature == 2 || item.nature == 3 || item.nature == 4 ? '' : formatCurrency(item.holdbackNumber) }}
        b-row(v-if="exportAccounting && (exportAccounting.exports || exportAccounting.label == 'ACD')")
          b-col.py-0.mb-1.text-right(cols="12")
            b-button(@click="downloadAccountingExport" variant="success" :disabled="exportAccounting.label == 'ACD' ? false : exportAccounting.exports.length == 0 && !exportAccounting.inequalNumberText && !exportAccounting.missingAccountText")
              | Exporter      
    popup-update-company-account-export(ref="popupUpdateCompanyAccountExport")
  </template>

<script>
import { BFormInput, BFormGroup, BFormCheckbox, BButton } from "bootstrap-vue";
import { debounce } from "lodash";
import vSelect from "vue-select";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { mask } from "vue-the-mask";

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
import PopupUpdateCompanyAccountExport from "./PopupUpdateCompanyAccountExport";
import { mapGetters, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  formatCurrency,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Ce mois-ci": [
    new Date(dayjs().startOf("month").hour(12)),
    new Date(dayjs().endOf("month")),
  ],
  "Mois dernier": [
    new Date(dayjs().subtract(1, "month").startOf("month").hour(12)),
    new Date(dayjs().subtract(1, "month").endOf("month")),
  ],
  "Ce trimestre": [
    new Date(dayjs().startOf("quarter").hour(12)),
    new Date(dayjs().endOf("quarter")),
  ],
  "Trimestre dernier": [
    new Date(dayjs().subtract(1, "quarter").startOf("quarter").hour(12)),
    new Date(dayjs().subtract(1, "quarter").endOf("quarter")),
  ],
  "Cette année": [
    new Date(dayjs().startOf("year").hour(12)),
    new Date(dayjs().endOf("year")),
  ],
  "Année dernière": [
    new Date(dayjs().subtract(1, "year").startOf("year").hour(12)),
    new Date(dayjs().subtract(1, "year").endOf("year")),
  ],
  "Depuis le début": [
    new Date(dayjs("2010-01-01")),
    new Date(dayjs().endOf("year")),
  ],
};

export default {
  data() {
    return {
      radio: "next",
      mouseOn: false,
      exportTypeId: null,
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      lineHover: false,
      config: config,
      defaultRange: defaultRange,
      startDateFiscalYear: {
        startDate: null,
        endDate: null,
      },
      endDateFiscalYear: {
        startDate: null,
        endDate: null,
      },
      filterRange: {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
    };
  },
  mounted() {
    this.exportAccounting = null;
    //startDateFiscalYear is the last end date of the last fiscal year
    this.startDateFiscalYear = {
      startDate: this.lastEndDateFiscalYear,
      endDate: this.lastEndDateFiscalYear,
    };
  },
  computed: {
    ...mapGetters([
      "workspaceSelected",
      "fiscalYearList",
      "institution",
      "isCreatingFiscalYear",
      "isLoadingFiscalYearList",
      "isLoadingExport",
      "isLoadingInvoicesCreditsOnTimeSpan",
    ]),
    lastEndDateFiscalYear() {
      if (this.fiscalYearList.length > 0) {
        let lastEndDateFiscalYear =
          this.fiscalYearList[this.fiscalYearList.length - 1].endDate;
        return new Date(dayjs(lastEndDateFiscalYear).add(1, "day"));
      } else {
        return null;
      }
    },
    startDateFiscalYearComputed() {
      if (this.startDateFiscalYear.startDate != null)
        return this.startDateFiscalYear.startDate;
      else return null;
    },
    exportAccounting: {
      get() {
        return this.$store.getters.exportAccounting;
      },
      set(value) {
        this.$store.commit("SET_EXPORT", value);
      },
    },
    institutionSettings: {
      get() {
        return this.$store.getters.institutionSettings;
      },
      set(value) {
        this.$store.commit("SET_INSTITUTION_SETTINGS", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "createFiscalYear",
      "getExportPeriodByInstitution",
      "deleteFiscalYears",
      "getInvoicesCreditsOnTimeSpan",
      "getExportUrl",
    ]),
    formatCurrency,
    formatDate,
    generateAccountingExport(id) {
      this.exportTypeId = id;
      this.getInvoicesCreditsOnTimeSpan({
        startTo: new Date(this.filterRange.startDate).toISOString(),
        endTo: new Date(this.filterRange.endDate).toISOString(),
      }).then((res) => {
        if (res.length > 0) {
          this.$refs[
            "popupUpdateCompanyAccountExport"
          ].popupUpdateCompanyAccountExportEvent(res);
        } else {
          this.getExportPeriodByInstitution({
            exportTypeId: id,
            startTo: new Date(this.filterRange.startDate).toISOString(),
            endTo: new Date(this.filterRange.endDate).toISOString(),
          });
        }
      });
    },
    downloadAccountingExport() {
      if (this.exportTypeId) {
        this.getExportUrl({
          exportTypeId: this.exportTypeId,
          startTo: new Date(this.filterRange.startDate).toISOString(),
          endTo: new Date(this.filterRange.endDate).toISOString(),
        }).then(async (res) => {
          if (res) {
            const response = await fetch(res);
            const text = await response.text();
            const fileName = res.split("/").pop();
            const link = document.createElement("a");
            link.href = URL.createObjectURL(
              new Blob([text], { type: "text/plain" })
            );
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        });
      }
    },
    createFiscalYearLocal() {
      let startDate = structuredClone(this.startDateFiscalYear.startDate);
      let endDate = structuredClone(this.endDateFiscalYear.endDate);
      if (startDate && endDate) {
        let fiscalYear = {
          startDate: startDate,
          endDate: endDate,
          institutionSettingId: this.institutionSettings.id,
          id: 0,
        };
        this.createFiscalYear({ fiscalYear }).then(() => {
          this.startDateFiscalYear = {
            startDate: this.lastEndDateFiscalYear,
            endDate: this.lastEndDateFiscalYear,
          };
          this.endDateFiscalYear = {
            startDate: null,
            endDate: null,
          };
        });
      } else {
        this.$bvToast.toast("Veuillez sélectionner une date de fin.", {
          title: `Erreur`,
          variant: "danger",
          solid: true,
        });
      }
    },
    statusFiscalYear(fiscalYear) {
      if (new Date(fiscalYear.endDate) < new Date()) {
        return "Terminé";
      } else if (
        new Date(fiscalYear.endDate) > new Date() &&
        new Date(fiscalYear.startDate) < new Date()
      ) {
        return "En cours";
      } else if (new Date(fiscalYear.endDate) > new Date()) {
        return "A venir";
      }
    },
    deleteFiscalYearLocal(fiscalYear, index) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            "Êtes-vous sûr de vouloir supprimer l'année fiscale \"" +
            formatDate(fiscalYear.startDate) +
            " - " +
            formatDate(fiscalYear.endDate) +
            '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.fiscalYearList.splice(index, 1);
            this.deleteFiscalYears({
              fiscalYearIds: [fiscalYear.id],
            });
          }
        });
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BFormGroup,
    VueTypeaheadBootstrap,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    DateRangePicker,
    PopupUpdateCompanyAccountExport,
  },
};
</script>

<style>
.editor-cgv .ql-editor {
  height: 500px !important;
}
#exportAccounting {
  border: 1px solid #dedde0 !important;
  border-radius: 5px;
  padding: 0px;
}
#exportAccounting .exportAccounting-head {
  display: flex;
  border-bottom: 1px solid #0c3571 !important;
  color: #0c3571 !important;
}
#exportAccounting .exportAccounting-head div {
  padding: 5px !important;
  border-right: 1px solid #dedde0 !important;
}
#exportAccounting .exportAccounting-head div:last-child {
  padding: 5px !important;
  border-right: none !important;
}
#exportAccounting .exportAccounting-content {
  display: flex;
  border-bottom: 1px solid #dedde0 !important;
}
#exportAccounting .exportAccounting-content:last-child > div {
  display: flex;
}
#exportAccounting .exportAccounting-content:last-child {
  display: flex;
  border-bottom: none !important;
}
#exportAccounting .exportAccounting-content:nth-child(even) {
  background-color: #f5f5f5 !important;
}
#exportAccounting .exportAccounting-content-children {
  display: flex;
  border-right: none !important;
  border-bottom: 1px solid #dedde0 !important;
}
#exportAccounting .exportAccounting-content-children div {
  border-right: 1px solid #dedde0 !important;
  padding: 5px !important;
}
#exportAccounting .exportAccounting-content-children div:last-child {
  border-right: none !important;
}
.first-row-exportAccounting {
  display: flex;
  width: 100%;
}
</style>
