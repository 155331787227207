<template lang="pug"> 
validation-observer(ref='formInstitution')
    b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
        .d-flex.flex-column
            .d-flex.w-100.py-0.justify-content-between.flex-md-row.flex-column
                div.d-flex.align-items-center(v-if='institutionForm.id')
                    form#uploadForm(enctype='multipart/form-data' v-on:change='uploadFile')
                        .d-flex.align-items-center.mb-1
                            #logoContainer.logo-container
                                .logo-loader.d-none
                                i.far.fa-spin.fa-spinner 
                                img#logoPreview(v-if="institutionForm.logo" :src='setLogo(institutionForm.logo)')
                                img#logoPreview(v-else src='@/assets/images/logo/no-logo.png')
                                input#logoTempId.additional(type='hidden' name='logo_preview' data-render='true' data-ease='true')
                            .ml-2
                                button#triggerLogoInput.btn.btn-outline-secondary.bg-white(@click="changeFile" type='button' data-toggle='click' size="sm" data-target='#logoInput' data-empty='Ajouter votre logo' data-prompt='Cliquez ici !' data-filled='Changer le logo')
                                    | Changer le logo
                                .hidden-file
                                    input#logoInput(type='file' name='logo')
                    .ml-2
                        //- b-form-group(label='Taille' label-for='bg-opacity' label-cols-sm='4' label-cols-lg='12')
                        b-form-input#sliderLogoWidth(v-model='institutionForm.logoWidth' type='range' min='60' max='260' step='10')

                .d-flex.align-items-center.mb-1(v-if="institutionsList.length > 1")
                    label.m-0.mr-1 Société par défaut          
                    b-form-checkbox.m-0.p-0(switch v-model="institutionForm.isDefault") 
        .d-flex.w-100.my-0.flex-md-row.flex-column
            .d-flex.w-100.flex-column.py-0.mb-1.mr-1
                b-form-group(label='Nom de votre entreprise *' label-for='Name')
                    validation-provider(#default='{ errors }' name='Name' rules='required')
                        b-form-input#name.not-autocomplete(v-model='institutionForm.name' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                            small.text-danger {{ errors[0] }}
            .d-flex.w-100.w-md-50.flex-column.py-0.mb-1.mr-1
                b-form-group(label='Code' label-for='Code')
                    validation-provider(#default='{ errors }' name='Code')
                        b-form-input#name.not-autocomplete(v-model='institutionForm.code' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                            small.text-danger {{ errors[0] }}
            .d-flex.w-100.w-md-50.py-0
                b-form-group.mb-1.w-100.mr-1(label='Forme juridique *' label-for='LegalForm' style='flex:1')
                    validation-provider(#default='{ errors }' name='LegalForm' rules='required')
                        v-select#legalForm(:loading='isLoadingLegalFormList' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='institutionForm.legalForm' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="\
                            legalFormList\
                            " :reduce='elem => elem.value')
                                template(v-slot:no-options='')
                                    template  Aucun r&eacute;sultat trouv&eacute;
                b-form-group.mb-1.mr-1.d-flex.flex-column.align-content-center.align-items-center.select-color-group(label='Couleur' label-for='color')
                    validation-provider(#default='{ errors }' name='Color')
                        ejs-colorpicker(id="color" v-model="institutionForm.color" mode="Palette")
                b-form-group.mb-1.d-flex.flex-column.align-content-center.align-items-center.select-color-group(label='Couleur 2' label-for='secondaryColor')
                    validation-provider(#default='{ errors }' name='secondaryColor')
                        ejs-colorpicker(id="secondaryColor" v-model="institutionForm.secondaryColor" mode="Palette")

        .d-flex.w-100.my-0.align-items-end
             .d-flex.w-100.flex-column.py-0.mb-1.mr-1
                b-form-group(label='Adresse *' label-for='address')
                    validation-provider(#default='{ errors }' name='Address')
                        vue-typeahead-bootstrap#address.not-autocomplete(type='text' :state='errors.length > 0 ? false : null' v-model='institutionForm.address' :ieCloseFix='false' :data='autocompleteAddressData' :serializer='item => item.properties.label' :showAllResults='true' placeholder='Rechercher une adresse ou saisissez : Rue et n° de rue' @input='autocompleteAddressSource()' @hit='autocompleteAddressSelected' autocomplete='nope' aria-autocomplete='nope')
                            template(slot='suggestion' slot-scope='{ data, htmlText }')
                                .item
                                    small {{data.properties.name}}
                                    span {{data.properties.postcode+ &apos; &apos; + data.properties.city}}
             .d-flex.w-100.flex-column.py-0.mb-1
                validation-provider(#default='{ errors }' name='AddressComplement')
                    b-form-input#addressComplement.not-autocomplete(v-model='institutionForm.addressComplement' :state='errors.length > 0 ? false : null' placeholder='Batiment, Appartement..' autocomplete='nope' aria-autocomplete='nope')
        .d-flex.w-100.my-0
            .d-flex.flex-column.py-0.mb-1.mr-1
                validation-provider(#default='{ errors }' name='ZipCode')
                    b-form-input#zipCode.not-autocomplete(v-model='institutionForm.zipCode' :state='errors.length > 0 ? false : null' placeholder='Code postal' autocomplete='nope' aria-autocomplete='nope')
            .d-flex.flex-column.w-100.py-0.mb-1
                validation-provider(#default='{ errors }' name='City')
                    b-form-input#city.not-autocomplete(v-model='institutionForm.city' :state='errors.length > 0 ? false : null' placeholder='Ville' autocomplete='nope' aria-autocomplete='nope')
        .d-flex.w-100.my-0
            .d-flex.flex-column.py-0.mb-1.w-100
                validation-provider(#default='{ errors }' name='Country')
                    b-form-input#country.not-autocomplete(v-model='institutionForm.country' :state='errors.length > 0 ? false : null' placeholder='Pays' autocomplete='nope' aria-autocomplete='nope')
                    small.text-danger {{ errors[0] }}
        .d-flex.flex-column.w-100.my-0
            .d-flex.flex-column.py-0.w-100
                label(for="address'") Coordonn&eacute;es GPS
            .d-flex.w-100
                .d-flex.flex-column.py-0.mb-1.w-50.mr-1
                    validation-provider(name='latitude')
                        b-form-input#latitude.mb-0(v-model='institutionForm.latitude' placeholder='Latitude')
                .d-flex.flex-column.py-0.mb-1.w-50
                    validation-provider(name='longitude')
                        b-form-input#longitude.mb-0(v-model='institutionForm.longitude' placeholder='Longitude')
        vs-divider.mt-0.mb-1(color='#cccccc')
        h3.mb-2 Moyens de communication
        .d-flex.flex-column.flex-md-row.my-0
            .d-flex.flex-column.w-100.py-0.mb-1.w-100.mr-0.mr-md-1
                b-form-group(label='Email' label-for='email')
                    validation-provider(#default='{ errors }' name='email' rules='email')
                        b-input-group
                            template(#prepend)
                                b-input-group-text.px-50.py-0.bg-gray
                                    span.material-icons-outlined.text-primary alternate_email
                            b-form-input#email(v-model='institutionForm.email' :state='errors.length > 0 ? false : null')
                        small.text-danger {{ errors[0] }}
            .d-flex.flex-column.w-100.py-0.mb-1.w-100
                b-form-group(label='Site internet' label-for='webSite')
                    validation-provider(#default='{ errors }' name='webSite')
                        b-input-group
                            template(#prepend)
                                b-input-group-text.px-50.py-0.bg-gray
                                    | https://
                            b-form-input#webSite(v-model='institutionForm.webSite' :state='errors.length > 0 ? false : null')
                        small.text-danger {{ errors[0] }}
        .d-flex.w-100.my-0.flex-column.flex-md-row
            .d-flex.flex-column.w-100.py-0.mb-1.mr-0.mr-md-1
                b-form-group(label='Téléphone principal' label-for='phoneNumber')
                    validation-provider(#default='{ errors }' name='Phone')
                        b-input-group
                            template(#prepend)
                                b-input-group-text.px-50.py-0.bg-gray
                                    span.material-icons-outlined.text-primary phone
                            b-form-input#phoneNumber(v-model='institutionForm.phoneNumber' :state='errors.length > 0 ? false : null')
            .d-flex.flex-column.w-100.py-0.mb-1(cols='4')
                b-form-group(label='Téléphone secondaire' label-for='secondaryPhoneNumber')
                    validation-provider(#default='{ errors }' name='secondaryPhoneNumber')
                        b-input-group
                            template(#prepend)
                                b-input-group-text.px-50.py-0.bg-gray
                                    span.material-icons-outlined.text-primary smartphone
                            b-form-input#secondaryPhoneNumber(v-model='institutionForm.secondaryPhoneNumber' :state='errors.length > 0 ? false : null')
        vs-divider.mt-0.mb-1(color='#cccccc')
        h3.mb-2 Informations juridiques
        .d-flex.w-100.my-0.flex-column.flex-md-row
            .d-flex.flex-column.w-100.py-0.mb-1.mr-0.mr-md-1
                b-form-group(label="N° de RCS" label-for='registrationNumber')
                    validation-provider(#default='{ errors }' name='registrationNumber')
                        b-form-input#registrationNumber(v-model='institutionForm.registrationNumber' :state='errors.length > 0 ? false : null')
                        small.text-danger {{ errors[0] }}
            .d-flex.flex-column.w-100.py-0.mb-1.mr-0.mr-md-1(v-if="secondStepLegalForm")
                b-form-group(label='N° de SIRET *' label-for='siret')
                    validation-provider(#default='{ errors }' name='siret')
                        b-form-input#siret(v-model='institutionForm.siret' :state='errors.length > 0 ? false : null')
                        small.text-danger {{ errors[0] }}
            .d-flex.flex-column.w-100.py-0.mb-1( v-if="secondStepLegalForm && thirdStepLegalForm")
                b-form-group(label="Ville du RCS *" label-for='rcsCity')
                    validation-provider(#default='{ errors }' name='rcsCity')
                        b-form-input#rcsCity(v-model='institutionForm.rcsCity' :state='errors.length > 0 ? false : null')
                        small.text-danger {{ errors[0] }}
        .d-flex.w-100.my-0(v-if="secondStepLegalForm")
            .d-flex.flex-column.w-100.py-0.mb-1.mr-1
                b-form-group(label="Code APE *" label-for='apeCode')
                    validation-provider(#default='{ errors }' name='apeCode')
                        b-form-input#apeCode(v-model='institutionForm.apeCode' :state='errors.length > 0 ? false : null')
                        small.text-danger {{ errors[0] }}      
            .d-flex.flex-column.w-100.py-0.mb-1
                b-form-group(label='Capital social (€) *' label-for='capital')
                    validation-provider(#default='{ errors }' name='capital')
                        b-input-group
                                template(#append)
                                    b-input-group-text.px-50.py-0.bg-gray
                                        span.material-icons.text-primary euro
                                b-form-input#capital(v-model='institutionForm.capital' type="number" :state='errors.length > 0 ? false : null')
                        small.text-danger {{ errors[0] }}
        .d-flex.w-100.my-0
            .d-flex.flex-column.w-100.align-self-center.mt-1.py-0.mb-1.mr-0.mr-md-1
                b-form-checkbox(v-model="institutionForm.isSubjectTVA")
                    | Assujeti à la TVA
                    validation-provider(#default='{ errors }' name='isSubjectTVA')
            .d-flex.flex-column.w-100.py-0.mb-1(cols='4')
                b-form-group(label='N° de TVA intracommunautaire *' label-for='tvaNumber')
                    validation-provider(#default='{ errors }' name='tvaNumber')
                        b-form-input#tvaNumber(v-model='institutionForm.tvaNumber' :state='errors.length > 0 ? false : null')
                        small.text-danger {{ errors[0] }}
        vs-divider.mt-0.mb-1(color='#cccccc')
        h3.mb-2 Garantie et Responsabilité
        .d-flex.w-100.flex-column.flex-md-row.my-0
            .d-flex.flex-column.w-100.py-0.mb-1.mr-0.mr-md-1
                b-form-group(label="Nom de l'assureur *" label-for='insuranceName')
                    validation-provider(#default='{ errors }' name='insuranceName')
                        b-form-input#insuranceName.not-autocomplete(v-model='institutionForm.insuranceName' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                        small.text-danger {{ errors[0] }}
            .d-flex.flex-column.w-100.d-flex.py-0.mb-1(cols='4')
                b-form-group(label='Garantie *' label-for='guaranteeType' style='flex:1')
                    validation-provider(#default='{ errors }' name='guaranteeType')
                        v-select#legalForm(:loading='isLoadingGuaranteeTypeList' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='institutionForm.guaranteeType' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="\
                        guaranteeTypeList\
                        " :reduce='elem => elem.value')
                            template(v-slot:no-options='')
                                template  Aucun r&eacute;sultat trouv&eacute; 
                        small.text-danger {{ errors[0] }}
        .d-flex.w-100
            .d-flex.w-100.flex-column.mb-1
                b-form-group(label="Zone de couverture *" label-for='insuranceCoverage')
                    validation-provider(#default='{ errors }' name='insuranceCoverage')
                        b-form-input#insuranceCoverage.not-autocomplete(v-model='institutionForm.insuranceCoverage' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                        small.text-danger {{ errors[0] }}
        .d-flex.w-100.align-items-end
            .d-flex.w-100.flex-column.mb-1.mr-1
                b-form-group(label="Adresse de l'assureur *" label-for='insuranceAddress')
                    validation-provider(#default='{ errors }' name='insuranceAddress')
                        vue-typeahead-bootstrap#insuranceAddress.not-autocomplete(type='text' :state='errors.length > 0 ? false : null' v-model='institutionForm.insuranceAddress' :ieCloseFix='false' :data='autocompleteAddressDataInsurance' :serializer='item => item.properties.label' :showAllResults='true' placeholder='Rechercher une adresse ou saisissez : Rue et n° de rue' @input='autocompleteAddressSourceInsurance()' @hit='autocompleteAddressSelectedInsurance' autocomplete='nope' aria-autocomplete='nope')
                            template(slot='suggestion' slot-scope='{ data, htmlText }')
                                .item
                                    small {{data.properties.name}}
                                    span {{data.properties.postcode+ &apos; &apos; + data.properties.city}}
            .d-flex.w-100.flex-column.mb-1
                validation-provider(#default='{ errors }' name='insuranceAddressComplement')
                    b-form-input#insuranceAddressComplement.not-autocomplete(v-model='institutionForm.insuranceAddressComplement' :state='errors.length > 0 ? false : null' placeholder='Batiment, Appartement..' autocomplete='nope' aria-autocomplete='nope')
        .d-flex.w-100
            .d-flex.w-25.flex-column.mb-1.mr-1
                validation-provider(#default='{ errors }' name='insuranceZipCode')
                    b-form-input#insuranceZipCode.not-autocomplete(v-model='institutionForm.insuranceZipCode' :state='errors.length > 0 ? false : null' placeholder='Code postal' autocomplete='nope' aria-autocomplete='nope')
            .d-flex.w-100.flex-column.mb-1
                validation-provider(#default='{ errors }' name='insuranceCity')
                    b-form-input#insuranceCity.not-autocomplete(v-model='institutionForm.insuranceCity' :state='errors.length > 0 ? false : null' placeholder='Ville' autocomplete='nope' aria-autocomplete='nope')
        .d-flex.w-100
             .d-flex.w-100.flex-column.mb-1
                validation-provider(#default='{ errors }' name='insuranceCountry')
                    b-form-input#insuranceCountry.not-autocomplete(v-model='institutionForm.insuranceCountry' :state='errors.length > 0 ? false : null' placeholder='Pays' autocomplete='nope' aria-autocomplete='nope')
                    small.text-danger {{ errors[0] }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BFormInput, BFormGroup, BFormCheckbox } from "bootstrap-vue";
import { debounce } from "lodash";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { mask } from "vue-the-mask";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import vSelect from "vue-select";
import Autocomplete from "vuejs-auto-complete";
import { BAlert, BLink } from "bootstrap-vue";

export default {
  props: {
    institutionForm: {},
  },
  data() {
    return {
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      autocompleteAddressDataInsurance: [],
      autocompleteAddressIndexInsurance: 0,
      dataOrigine: {},
      file: "",
    };
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters([
      "legalFormList",
      "guaranteeTypeList",
      "isLoadingLegalFormList",
      "isLoadingGuaranteeTypeList",
      "institutionsList",
    ]),
    secondStepLegalForm() {
      if (
        this.institutionForm.legalForm != 0 &&
        this.institutionForm.legalForm != 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    thirdStepLegalForm() {
      if (
        this.institutionForm.legalForm == 4 ||
        this.institutionForm.legalForm == 5 ||
        this.institutionForm.legalForm == 6 ||
        this.institutionForm.legalForm == 7 ||
        this.institutionForm.legalForm == 8
      ) {
        return true;
      } else {
        return false;
      }
    },
    mandatoryInformationMissingNumber() {
      let mandatoryInformation = [
        "siret",
        "rcsCity",
        "capital",
        "apeCode",
        "tvaNumber",
        "insuranceName",
        "guaranteeType",
        "insuranceCoverage",
        "insuranceAddress",
      ];
      let missing = 0;
      for (let i = 0; i < mandatoryInformation.length; i++) {
        const mandatory = mandatoryInformation[i];
        if (
          this.institutionForm[mandatory] === null ||
          this.institutionForm[mandatory] === ""
        ) {
          missing++;
        }
      }
      return missing;
    },
  },
  created() {
    this.getLegalFormEnum({});
    this.getGuaranteeTypeEnum({});
  },
  methods: {
    ...mapActions(["getLegalFormEnum", "getGuaranteeTypeEnum", "uploadLogo"]),

    autocompleteAddressSource: debounce(function (indexSource) {
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.institutionForm.address}&autocomplete=0&limit=50`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.institutionForm.address = item.properties.name;
      this.institutionForm.city = item.properties.city;
      this.institutionForm.zipCode = item.properties.postcode;
      this.institutionForm.latitude = item.geometry.coordinates[1];
      this.institutionForm.longitude = item.geometry.coordinates[0];
      this.institutionForm.country = "France";
    },
    autocompleteAddressSourceInsurance: debounce(function (indexSource) {
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.institutionForm.insuranceAddress}&autocomplete=0`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressDataInsurance = data.features;
          this.autocompleteAddressIndexInsurance = indexSource;
        });
    }, 500),
    autocompleteAddressSelectedInsurance(item) {
      this.institutionForm.insuranceAddress = item.properties.name;
      this.institutionForm.insuranceCity = item.properties.city;
      this.institutionForm.insuranceZipCode = item.properties.postcode;
      this.institutionForm.insuranceCountry = "France";
    },

    changeFile() {
      document.getElementById("logoInput").click();
    },
    setLogo(img) {
      return img + `?v=${new Date().getTime()}`;
    },
    uploadFile(e) {
      let formData = new FormData();
      let file = e.target.files[0];
      formData.append("file", file, "logo");
      this.uploadLogo({
        file: formData,
      }).then((res) => {
        this.institutionForm.logo = res;
        document.getElementById("logoPreview").src =
          res + `?v=${new Date().getTime()}`;
      });
    },
  },
  components: {
    BFormInput,
    BFormGroup,
    VueTypeaheadBootstrap,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
    Autocomplete,
    BAlert,
    BLink,
  },
};
</script>
<style>
.header-settings {
  position: sticky;
  top: 0px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  z-index: 99;
  width: 100%;
}

.logo-container {
  position: relative;
}

.logo-container .logo-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  background: rgba(244, 245, 248, 0.75);
}

.logo-container img {
  max-height: 60px;
  max-width: 60px;
}

.hidden-file {
  width: 0;
  height: 0;
  display: none;
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  left: -1000px;
}

.select-color-group > div .e-colorpicker-wrapper .e-split-btn-wrapper {
  height: auto !important;
}
</style>
