<template lang="pug">
validation-observer(ref='formInstitutionPreferences')
  b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
    h3.mb-2 Paramétrage par défaut de vos avoirs
    //- b-row.my-0
    //-     b-col.py-0.mb-1(cols='12')
    //-       b-form-group(label='Numéro de départ de la séquence' label-for='documentNumberCredit' style='flex:1')
    //-         validation-provider(#default='{ errors }' name='documentNumberCredit' rules="required")
    //-           ejs-numerictextbox(disabled v-model="creditOptionDefault.DocumentNumberCredit")
    //- b-row.my-0
    //-   b-col.py-0.mb-1(cols='12')
    //-     b-form-group(label='Condition de paiement *' label-for='paymentConditionCreditDefaultId' style='flex:1')
    //-       validation-provider(#default='{ errors }' name='paymentConditionCreditDefaultId' rules="required")
    //-         v-select#paymentConditionCreditDefaultId(:class='!creditOptionDefault.paymentCondition && errorsCustom ? "style-chooser": ""' :loading='isLoadingPaymentConditionsList' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='creditOptionDefault.paymentCondition' :options='paymentConditionsList' :reduce='elem => elem.id')
    //-           template(v-slot:no-options='')
    //-               template  Aucune condition trouv&eacute;
    b-row.my-0()
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Notes de bas de page' label-for='commentInvoiceDefault')
          validation-provider(#default='{ errors }' name='commentInvoiceDefault')
            quill-editor(v-model="creditOptionDefault.comment" :options="editorOption" class="edit" )
    b-row.my-0()
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Mentions légales' label-for='additionnalLegalNoticeOrderFormCustomerDefault')
          validation-provider(#default='{ errors }' name='additionnalLegalNoticeOrderFormCustomerDefault')
            quill-editor(v-model="creditOptionDefault.additionalLegalNotices" :options="editorOption" class="edit" )
    vs-divider.mt-1.mb-1(color='#cccccc')
    h3 Options par défaut des avoirs
    p.mb-2 Ces options seront appliquées par défaut à tous vos prochains avoirs.
    .d-flex.flex-wrap
      .pr-2.checkbox-column
        h4.primary.pb-50 Edition
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.calculateMargin")
              | Calculer la marge
          //- .d-flex.w-100.pb-1
          //-   b-form-checkbox(v-model="creditOptionDefault.calculateUnitPriceHtByUnitPurchasePriceHt")
          //-     | Calculer le prix de vente par rapport au prix d'achat
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.showDiscountColumn")
              | Afficher la colonne des remises par ligne
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.showReferenceColumn")
              | Afficher la colonne des références
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.isReverseCharge")
              | Activer l'autoliquidation
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.showSituationDetails")
              | Afficher le récapitulatif de facturation
      .pr-2.checkbox-column
        h4.primary.pb-50 Prévisualisation
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideInstitutionLogo")
              | Masquer mon logo
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideInstitutionName")
              | Masquer ma raison sociale
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideInstitutionAddress")
              | Masquer mon adresse
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="creditOptionDefault.hideCollaboratorInCharge")
              | Masquer le collaborateur en charge
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideAffair")
              | Masquer l'affaire
          .d-flex.w-100.mb-1.sub-checkbox
            b-form-checkbox(v-model="creditOptionDefault.showAffairCode" :disabled="creditOptionDefault.hideAffair")
              | Masquer le code de l'affaire
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideLinesQuantityEmpty")
              | Masquer les lignes aux quantités nulles
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideTotal")
              | Masquer le total
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="creditOptionDefault.showWaterMark")
              | Afficher le filigrane
          //- .d-flex.w-100.pb-1
          //-   b-form-checkbox(v-model="creditOptionDefault.showDetailWorkLine")
          //-     | Afficher le détail des ouvrages
          //- .d-flex.w-100.pb-1
          //-   b-form-checkbox(v-model="creditOptionDefault.showThumbnail")
          //-     | Afficher la miniature des produits
          //- .d-flex.w-100.pb-1
          //-   b-form-checkbox(v-model="creditOptionDefault.showProductPicturesAttached")
          //-     | Afficher les images des produits en pièce jointe
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.showTermsOfSales")
              | Afficher les conditions de vente
      .pr-2.checkbox-column
        h4.primary.pb-50 Document
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideUser")
              | Masquer le contact
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideIndexColumn")
              | Masquer la colonne des index
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideQuantityColumn")
              | Masquer la colonne des quantités
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideUnitColumn")
              | Masquer la colonne des unités
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideUnitPriceHtColumn")
              | Masquer la colonne des prix unitaire HT
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hideReferencielTvaColumn")
              | Masquer la colonne des TVA
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="creditOptionDefault.hidePriceHtColumn")
              | Masquer la colonne des totaux HT
      .checkbox-column
        h4.primary.pb-50 Client
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="creditOptionDefault.hideCompany")
              | Masquer le client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="creditOptionDefault.showCompanyCode" :disabled="creditOptionDefault.hideCompany")
              | Afficher le code du client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="creditOptionDefault.showCompanyAddressLabel" :disabled="creditOptionDefault.hideCompany")
              | Afficher le libellé de l'adresse
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="creditOptionDefault.showCompanyMail" :disabled="creditOptionDefault.hideCompany")
              | Afficher l'adresse mail
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="creditOptionDefault.showCompanyPhoneNumber" :disabled="creditOptionDefault.hideCompany")
              | Afficher le numéro de téléphone
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="creditOptionDefault.showCompanySiret" :disabled="creditOptionDefault.hideCompany")
              | Afficher le numéro de SIRET
          .d-flex.w-100.sub-checkbox
            b-form-checkbox(v-model="creditOptionDefault.showCompanyTva" :disabled="creditOptionDefault.hideCompany")
              | Afficher le numéro de TVA intracommunautaire
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  props: {
    errorsCustom: {
      default: false,
    },
  },
  data() {
    return {
      editFocusAdditionnalLegalNotice: false,
      editFocusComment: false,
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "getPaymentConditionByWorkspaceId",
      "getCreditOptionDefaultByCreditId",
    ]),
    // focusFunction(val, type) {
    //   if (type == "comment") {
    //     this.editFocusComment = val;
    //   } else if (type == "legalNotice") {
    //     this.editFocusAdditionnalLegalNotice = val;
    //   }
    // },
  },
  created() {
    if (!this.paymentConditionsList || this.paymentConditionsList.length == 0) {
      this.getPaymentConditionByWorkspaceId({});
    }
    this.getCreditOptionDefaultByCreditId({});
  },
  computed: {
    ...mapGetters(["paymentConditionsList", "isLoadingPaymentConditionsList"]),
    creditOptionDefault: {
      get() {
        return this.$store.getters.creditOptionDefaultForm;
      },
      set(value) {
        this.$store.commit("SET_CREDIT_OPTION_DEFAULT_FORM", value);
      },
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
};
</script>
<style lang=""></style>
