<template lang="pug">
validation-observer(ref='formInstitutionPreferences')
  b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
    h3.mb-2 Paramétrage par défaut de vos bons de sortie
    // Mettre le formulaire ici.
    b-row.my-0()
      b-col.py-0.mb-1(cols='12')
        b-form-group(label='Notes de bas de page' label-for='commentDeliveryFormDefault')
          validation-provider(#default='{ errors }' name='commentDeliveryFormDefault')
            quill-editor(v-model="deliveryFormOptionDefault.comment" :options="editorOption" class="edit")
    vs-divider.mt-1.mb-1(color='#cccccc')
    h3 Options par défaut des bons de sortie
    p.mb-2 Ces options seront appliquées par défaut à tous vos prochains bons de sortie.
    .d-flex.flex-wrap
      .pr-2.checkbox-column
        h4.primary.pb-50 Edition
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.showReferenceColumn")
              | Afficher la colonne des références
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.isReverseCharge")
              | Activer l'autoliquidation
      .pr-2.checkbox-column
        h4.primary.pb-50 Prévisualisation
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideInstitutionLogo")
              | Masquer mon logo
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideInstitutionName")
              | Masquer ma raison sociale
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideInstitutionAddress")
              | Masquer mon adresse
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideAffair")
              | Masquer l'affaire
          .d-flex.w-100.mb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showAffairCode" :disabled="deliveryFormOptionDefault.hideAffair")
              | Masquer le code de l'affaire
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideStorageLocation")
              | Masquer le point de stockage
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideDestockingLocation")
              | Masquer le point de déstockage
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideCollaborator")
              | Masquer le collaborateur
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideCompanySubContractor")
              | Masquer le sous-traitant
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideTotal")
              | Masquer le total
      .pr-2.checkbox-column
        h4.primary.pb-50 Document
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideUser")
              | Masquer le contact
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideIndexColumn")
              | Masquer la colonne des index
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideOutPutQuantity")
              | Masquer la colonne des quantités sorties
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideUnitColumn")
              | Masquer la colonne des unités
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideUnitPriceHtColumn")
              | Masquer la colonne des prix unitaire HT
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideReferencielTvaColumn")
              | Masquer la colonne des TVA
          .d-flex.w-100.pb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hidePriceHtColumn")
              | Masquer la colonne des totaux HT
      .checkbox-column
        h4.primary.pb-50 Client
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideCompanyCustomer")
              | Masquer le client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanyCustomerCode" :disabled="deliveryFormOptionDefault.hideCompanyCustomer")
              | Afficher le code du client
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanyCustomerAddressLabel" :disabled="deliveryFormOptionDefault.hideCompanyCustomer")
              | Afficher le libellé de l'adresse
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanyCustomerMail" :disabled="deliveryFormOptionDefault.hideCompanyCustomer")
              | Afficher l'adresse mail
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanyCustomerPhoneNumber" :disabled="deliveryFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de téléphone
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanyCustomerSiret" :disabled="deliveryFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de SIRET
          .d-flex.w-100.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanyCustomerTvaNumber" :disabled="deliveryFormOptionDefault.hideCompanyCustomer")
              | Afficher le numéro de TVA intracommunautaire
        h4.primary.pb-50 Sous-traitant
        .d-flex.flex-column.justify-content-start
          .d-flex.w-100.mb-1
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideCompanySubcontractor")
              | Masquer le sous-traitant
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.hideCompanySubContractorCode" :disabled="deliveryFormOptionDefault.hideCompanySubContractor")
              | Afficher le code du sous-traitant
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanySubContractorAddressLabel" :disabled="deliveryFormOptionDefault.hideCompanySubContractor")
              | Afficher le libellé de l'adresse
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanySubContractorMail" :disabled="deliveryFormOptionDefault.hideCompanySubContractor")
              | Afficher l'adresse mail
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanySubContractorPhoneNumber" :disabled="deliveryFormOptionDefault.hideCompanySubContractor")
              | Afficher le numéro de téléphone
          .d-flex.w-100.pb-1.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanySubContractorSiret" :disabled="deliveryFormOptionDefault.hideCompanySubContractor")
              | Afficher le numéro de SIRET
          .d-flex.w-100.sub-checkbox
            b-form-checkbox(v-model="deliveryFormOptionDefault.showCompanySubContractorTvaNumber" :disabled="deliveryFormOptionDefault.hideCompanySubContractor")
              | Afficher le numéro de TVA intracommunautaire
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  data() {
    return {
      editFocusComment: false,
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  created() {
    this.getDeliveryFormOptionDefaultByDeliveryFormId({});
  },
  methods: {
    ...mapActions(["getDeliveryFormOptionDefaultByDeliveryFormId"]),
    // focusFunction(val, type) {
    //   if (type == "comment") {
    //     this.editFocusComment = val;
    //   }
    // },
  },
  computed: {
    ...mapGetters(["documentValidityList", "isLoadingDocumentValidityList"]),
    deliveryFormOptionDefault: {
      get() {
        return this.$store.getters.deliveryFormOptionDefaultForm;
      },
      set(value) {
        this.$store.commit("SET_DELIVERY_FORM_OPTION_DEFAULT_FORM", value);
      },
    },
  },
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
<style lang=""></style>
