<template lang="pug">

validation-observer(ref='formInstitutionPreferences')
    b-form.w-100.p-1(autocomplete='off' style="height: calc(100% - 46px); overflow-y: auto;")
        h3.mb-2 Méthodes de paiement
        b-form-group.mb-2(label-for='paymentMethodsDefaults')
          validation-provider(#default='{ errors }' name='paymentMethodsDefaults')
            b-form-checkbox(v-for='option in paymentMethodsList' :key='option.id' v-model='institutionSettings.paymentMethodsDefaults' :value='option')
              | {{ option.label }}

        vs-divider.mt-1.mb-1(color='#cccccc')
        h3 Acomptes
        .justify-content-center.h-100(v-if="isCreatingQuoteDownPaymentsRequest || isUpdatingQuoteDownPaymentsRequest || isDeletingQuoteDownPaymentsRequest || isLoadingQuoteDownPaymentsRequestsList")
          .content-loader-center.m-0.h-100
            .text-center.flex-center
              .loading-bg-inner(variant="primary" style="width: 3rem; height: 3rem;")
                .loader
                  .outer
                  .middle
                  .inner
        .d-flex.align-items-start.align-items-md-center.bg-mobile.mb-1.mb-md-0.p-1.p-md-0.mb-0.mb-md-1(v-else v-for="(element,index) of institutionSettings.quoteDownPaymentsRequestDefaults")
          .d-flex.flex-column.flex-md-row.my-0.w-100.align-items-end
                .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                  label.w-100(label-for='quoteDownPaymentsRequestsListNumber' v-if="index==0") Nombre
                  b-form-group.w-100
                    b-form-input#creditAccount.w-100.not-autocomplete(type="number" name="quoteDownPaymentsRequestsListNumber" v-model='element.number' placeholder='30' autocomplete='nope' aria-autocomplete='nope')
                .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                  label.w-100(label-for='quoteDownPaymentsRequestsListType' v-if="index==0") Type
                  b-form-group.w-100
                    validation-provider(#default='{ errors }' name='quoteDownPaymentsRequestsListType')
                      v-select#amountType(:loading='isLoadingAmountTypeList' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='element.downPaymentRequestType' :options="amountTypeList" :reduce='elem => elem.value')
                        template(v-slot:no-options='')
                          template  Aucun type trouv&eacute;
                .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                  label.w-100(label-for='quoteDownPaymentsRequestsListLabel' v-if="index==0") Description 
                  b-form-group.w-100(v-if="element.downPaymentRequestDue == 3" label-for='quoteDownPaymentsRequestsListLabel' style='flex:1')
                    validation-provider.d-flex.w-100(#default='{ errors }' name='quoteDownPaymentsRequestsListLabel')
                      b-form-input(v-model="element.label" placeholder="Texte libre")
                      feather-icon.cursor-pointer(icon="XIcon" size="18" style="margin-left:-25px; margin-top:9px" @click="element.downPaymentRequestDue=0" v-b-tooltip.hover.top="'Changer'")
                  b-form-group.w-100(v-else label-for='quoteDownPaymentsRequestsListLabel' style='flex:1')
                    validation-provider(#default='{ errors }' name='quoteDownPaymentsRequestsListLabel')
                      v-select#paymentRequestDue(:loading='isLoadingPaymentRequestDueList' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='element.downPaymentRequestDue' :options="paymentRequestDueList" :reduce='elem => elem.value')
                        template(v-slot:no-options='')
                          template  Aucun r&eacute;sultat trouv&eacute;
                b-button.mt-1.py-0.ml-0.ml-md-1(style="height: 32px" variant="outline-danger" @click="deleteQuoteDownRequestForm(index, element)")
                  feather-icon.m-0(icon="TrashIcon")

        div.mb-1(@click="addQuoteDownRequestForm")
          small.cursor-pointer.text-primary + Ajouter une demande d'acompte par défaut

        vs-divider.mt-1.mb-1(color='#cccccc')
        h4 Comptes bancaires

        .justify-content-center.h-100(v-if="isCreatingBankAccount || isUpdatingBankAccount || isDeletingBankAccount || isLoadingBankAccountsList")
          .content-loader-center.m-0.h-100
            .text-center.flex-center
              b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                span.sr-only Chargement...
        .d-flex.align-items-start.align-items-md-center.bg-mobile.mb-1.mb-md-0.p-1.p-md-0.mb-0.mb-md-1(v-else v-for="(element,index) of bankAccountsListForm")
           .d-flex.flex-column.flex-md-row.my-0.w-100.align-items-end
                  .d-flex.w-fit.py-0.mb-1.mb-md-0.d-flex.flex-column.align-items-center.mr-0.mr-md-1
                    b-form-group.w-fit.text-center.cursor-pointer
                      b-form-checkbox.w-fit(v-model="element.isDefault" inline @input="changeDefautPayement(index, element.isDefault)" :disabled="element.isDefault")
                  .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                    label.w-100(label-for='creditAccount') Nom du compte *
                    b-form-group.w-100
                      b-form-input#creditAccount.w-100.not-autocomplete(type="text" v-model='element.label' placeholder='Compte principal' autocomplete='nope' aria-autocomplete='nope')
                  .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                    label.w-100(label-for='creditAccount') Nom de la banque *
                    b-form-group.w-100
                      b-form-input#creditAccount.w-100.not-autocomplete(type="text" v-model='element.name' placeholder='BNP' autocomplete='nope' aria-autocomplete='nope')
                  .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                    label.w-100(label-for='creditAccount') IBAN *
                    b-form-group.w-100
                      b-form-input#creditAccount.w-100.not-autocomplete(type="text" v-model='element.iban' placeholder='Compte principal' autocomplete='nope' aria-autocomplete='nope')
                  .d-flex.w-100.py-0.mb-1.mb-md-0.d-flex.flex-column.mr-0.mr-md-1
                    label.w-100(label-for='creditAccount') BIC *
                    b-form-group.w-100
                      b-form-input#creditAccount.w-100.not-autocomplete(type="text" v-model='element.bic' placeholder='Compte principal' autocomplete='nope' aria-autocomplete='nope')
                  b-button.mt-1.py-0.ml-0.ml-md-1(style="height: 32px" variant="outline-danger" @click="deleteBankAccountForm(index, element)")
                    feather-icon.m-0(icon="TrashIcon")
        div.mb-1(@click="addBankAccountForm")
          small.cursor-pointer.text-primary + Ajouter un compte bancaire
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  BFormInput,
  BTable,
  BFormGroup,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { debounce } from "lodash";
import vSelect from "vue-select";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { mask } from "vue-the-mask";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
import { isDef } from "@vueuse/core";

export default {
  computed: {
    ...mapGetters([
      "quoteDownPaymentsRequestsList",
      "institution",
      "paymentMethodsList",
      "amountTypeList",
      "isLoadingAmountTypeList",
      "paymentRequestDueList",
      "isLoadingPaymentRequestDueList",
      "isCreatingBankAccount",
      "isUpdatingBankAccount",
      "isDeletingBankAccount",
      "isLoadingBankAccountsList",
      "isCreatingQuoteDownPaymentsRequest",
      "isUpdatingQuoteDownPaymentsRequest",
      "isDeletingQuoteDownPaymentsRequest",
      "isLoadingQuoteDownPaymentsRequestsList",
    ]),
    bankAccountsListForm: {
      get() {
        return this.$store.getters.bankAccountsListForm;
      },
      set(value) {
        this.$store.commit("SET_BANK_ACCOUNTS_LIST_FORM", value);
      },
    },
    institutionSettings: {
      get() {
        return this.$store.getters.institutionSettings;
      },
      set(value) {
        this.$store.commit("SET_INSTITUTION_SETTINGS", value);
      },
    },
  },
  created() {
    this.getAmountTypeEnum({});
    if (!this.paymentMethodsList || this.paymentMethodsList.length == 0) {
      this.getPaymentMethodByWorkspaceId({});
    }
    if (!this.paymentRequestDueList || this.paymentRequestDueList.length == 0) {
      this.getPaymentRequestDueEnum({});
    }
  },
  methods: {
    ...mapActions([
      "deleteBankAccounts",
      "getAmountTypeEnum",
      "getPaymentMethodByWorkspaceId",
      "getPaymentRequestDueEnum",
      "deleteQuoteDownPaymentsRequest",
    ]),
    addBankAccountForm() {
      this.bankAccountsListForm.push({
        id: 0,
        label: "",
        name: "",
        iban: "",
        bic: "",
        isDefault: false,
      });
    },
    addQuoteDownRequestForm() {
      this.institutionSettings.quoteDownPaymentsRequestDefaults.push({
        id: 0,
        number: 30,
        downPaymentRequestType: null,
      });
    },
    deleteBankAccountForm(index, element) {
      if (element.id > 0) {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              'Êtes-vous sûr de vouloir supprimer le compte bancaire "' +
              element.label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Supprimer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.deleteBankAccounts({ bankAccountIds: [element.id] });
            }
          });
      } else {
        this.bankAccountsListForm.splice(index, 1);
      }
    },
    deleteQuoteDownRequestForm(index, element) {
      if (element.id > 0) {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir supprimer la demande d'acompte par défaut \"" +
              element.label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Supprimer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.deleteQuoteDownPaymentsRequest({
                quoteDownPaymentsRequestIds: [element.id],
                route: "institutionSettings",
              });
            }
          });
      } else {
        this.institutionSettings.quoteDownPaymentsRequestDefaults.splice(
          index,
          1
        );
      }
    },

    addQuoteDownRequest() {
      this.institutionSettings.quoteDownPaymentsRequestDefaults.push({
        number: null,
        downPaymentRequestType: null,
        downPaymentRequestDue: null,
        label: null,
        quoteId: null,
        institutionSettingsId: this.institutionSettings.id,
        id: 0,
      });
    },
    removeQuoteDownRequest(index, quoteDownRequest) {
      if (quoteDownRequest.id == 0) {
        this.institutionSettings.quoteDownPaymentsRequestDefaults.splice(
          index,
          1
        );
      } else {
        this.deleteQuoteDownPaymentsRequest({
          quoteDownPaymentsRequestIds: [item.id],
          route: "institutionSettings",
        }).then(() => [
          this.institutionSettings.quoteDownPaymentsRequestDefaults.splice(
            index,
            1
          ),
        ]);
      }
    },

    changeDefautPayement(number, isDefault) {
      if (isDefault) {
        for (let index = 0; index < this.bankAccountsListForm.length; index++) {
          if (index == number) {
            this.bankAccountsListForm[index].isDefault = true;
          } else {
            this.bankAccountsListForm[index].isDefault = false;
          }
        }
      }
    },
  },
  directives: {
    mask,
    Ripple,
  },
  components: {
    BTable,
    BFormInput,
    BFormGroup,
    VueTypeaheadBootstrap,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
