var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formInstitutionLegalNotice"},[_c('b-form',{staticClass:"w-100 p-1",staticStyle:{"height":"calc(100% - 46px)","overflow-y":"auto"},attrs:{"autocomplete":"off"}},[_c('h3',[_vm._v("Pieds de page des documents")]),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label-for":"footerType"}},[_c('validation-provider',{attrs:{"name":"footerType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormRadio',{staticClass:"mb-1",attrs:{"value":0},on:{"input":_vm.generateFooter},model:{value:(_vm.institutionSettings.footerType),callback:function ($$v) {_vm.$set(_vm.institutionSettings, "footerType", $$v)},expression:"institutionSettings.footerType"}},[_vm._v("Texte généré automatiquement")]),_c('BFormRadio',{attrs:{"value":1},model:{value:(_vm.institutionSettings.footerType),callback:function ($$v) {_vm.$set(_vm.institutionSettings, "footerType", $$v)},expression:"institutionSettings.footerType"}},[_vm._v("Texte libre")])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0",on:{"mouseover":function($event){_vm.mouseOn = true},"mouseleave":function($event){_vm.mouseOn = false}}},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label-for":"commentQuoteDefault"}},[_c('validation-provider',{attrs:{"name":"commentQuoteDefault"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"edit",attrs:{"disabled":_vm.institutionSettings.footerType == 0,"options":_vm.editorOption},model:{value:(_vm.institutionSettings.footer),callback:function ($$v) {_vm.$set(_vm.institutionSettings, "footer", $$v)},expression:"institutionSettings.footer"}})]}}])})],1)],1)],1),_c('vs-divider',{staticClass:"mt-1 mb-1",attrs:{"color":"#cccccc"}}),_c('h3',[_vm._v("Conditions générales de vente")]),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('div',{on:{"mouseover":function($event){_vm.mouseOnCGV = true},"mouseleave":function($event){_vm.mouseOnCGV = false}}},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label-for":"description"}},[_c('quill-editor',{staticClass:"quill-editor-scroll edit editor-cgv",attrs:{"options":_vm.editorOption},model:{value:(_vm.institutionSettings.termsOfSales),callback:function ($$v) {_vm.$set(_vm.institutionSettings, "termsOfSales", $$v)},expression:"institutionSettings.termsOfSales"}})],1)],1)])],1),_c('b-row',{staticClass:"my-0"},[_c('div',[_c('b-row',{staticClass:"my-0 ml-1"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label-for":"showTermsOfSales"}},[_c('validation-provider',{attrs:{"name":"showTermsOfSales"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormCheckbox',{model:{value:(_vm.quoteOptionDefault.showTermsOfSales),callback:function ($$v) {_vm.$set(_vm.quoteOptionDefault, "showTermsOfSales", $$v)},expression:"quoteOptionDefault.showTermsOfSales"}},[_vm._v("Afficher sur les devis")])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0 ml-1"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label-for":"showTermsOfSalesInvoice"}},[_c('validation-provider',{attrs:{"name":"showTermsOfSalesInvoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormCheckbox',{model:{value:(_vm.invoiceOptionDefault.showTermsOfSales),callback:function ($$v) {_vm.$set(_vm.invoiceOptionDefault, "showTermsOfSales", $$v)},expression:"invoiceOptionDefault.showTermsOfSales"}},[_vm._v("Afficher sur les factures")])]}}])})],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }